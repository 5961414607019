import React from "react";
import { MDBAnimation } from "mdbreact";
import { withRouter } from "react-router-dom";
import Elderly from "../../Components/BfrExpert/Elderly";

const Senior = () => {
  
    return (
        <>
           
            <MDBAnimation type="fadeIn">
            <Elderly/>

            </MDBAnimation>
        </>
    );
};

export default withRouter(Senior);