import React, { useState, useEffect } from "react";
import "./Blog.scss";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import "./Blog.scss";
import * as dataJSON from "../../Data/Blog.json";

const LatestArticle = (props) => {
    const scrollToTop = () => window.scrollTo(0, 0);
    const [data, setData] = useState(dataJSON.default[props.match.params.lang]);
    const [articles, setArticles] = useState([]);
    useEffect(() => {
        const madUpService = new MadUpService();
        madUpService.getAllArticles(props.match.params.lang).then((res) => {
            setArticles(res.data);
            setData(dataJSON.default[props.match.params.lang]);
        });
    }, [props.match.params.lang]);

    return (
        <div className="blog-sidebar_latest">
            <div className="blog-sidebar_category_title">
                {data.sidebar.latest}
            </div>
            <nav>
                {articles.map((item,index) => {
                    function convertDate(inputFormat) {
                        function pad(s) {
                            return s < 10 ? "0" + s : s;
                        }
                        var d = new Date(inputFormat);
                        return [
                            pad(d.getDate()),
                            pad(d.getMonth() + 1),
                            d.getFullYear(),
                        ].join("/");
                    }
                    return (
                        <div
                            className="blog-sidebar_latest_item"
                            onClick={() => {
                                props.history.push(
                                    `/${props.match.params.lang}/actualite/${item.category.slug}/${item.slug}`
                                );
                                window.blog_id = item.id;
                                scrollToTop();
                            }}
                            key={`blog-sidebar_latest_item${index}`}
                        >
                            <div
                                className="blog-sidebar_latest_image"
                                style={{
                                    background: `url(${item.cover.data.full_url})`,
                                }}
                            ></div>
                            <div className="blog-sidebar_latest_text">
                                <div className="blog-sidebar_latest_date">
                                    {convertDate(item.created_on)}
                                </div>
                                <div className="blog-sidebar_latest_title">
                                    {item.translations[0].title}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </nav>
        </div>
    );
};

export default withRouter(LatestArticle);
