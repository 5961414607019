import React, { createRef } from "react";
import BigTitle from "../../Components/BigTitle";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/DemoRequest.json";
import "./DemoRequest.scss";
import Meta from "../../Components/Meta";
import { MDBRow, MDBCol, MDBInput, MDBAnimation, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

class DemoRequest extends React.Component {
  state = {
    lang: this.props.match.params.lang,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    company_name: "",
    subject: "",
    message: "",
    country: "",
    region: "",
    data: {},
    meta: {
      title: dataJSON.default[this.props.match.params.lang].meta.title,
      description: dataJSON.default[this.props.match.params.lang].meta.description,
      canonical: dataJSON.default[this.props.match.params.lang].meta.canonical,
      image: dataJSON.default[this.props.match.params.lang].meta.image,
      keywords: dataJSON.default[this.props.match.params.lang].meta.keywords
    },
    bigtitle: {},
    label: {},
    validate: {},
    isVerified: false
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcRCgYhAAAAAE959V_cpYVPav7dscBaucxP8jc_";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    this.setState({
      lang: this.props.match.params.lang,
      data: dataJSON.default[this.state.lang],
      meta: dataJSON.default[this.state.lang].meta,
      bigtitle: dataJSON.default[this.state.lang].bigtitle,
      label: dataJSON.default[this.state.lang].label,
      validate: dataJSON.default[this.state.lang].validate
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang !== this.props.match.params.lang) {
      this.setState({
        lang: this.props.match.params.lang,
        data: dataJSON.default[this.state.lang],
        meta: dataJSON.default[this.state.lang].meta,
        bigtitle: dataJSON.default[this.state.lang].bigtitle,
        label: dataJSON.default[this.state.lang].label,
        validate: dataJSON.default[this.state.lang].validate
      });
    }
  }
  submitHandler = (event) => {
    event.preventDefault();

    const form = event.target;
    const submitBtn = event.target.querySelector("[type=submit]");
    const validate = document.querySelector(".form-validate");

    form.classList.add('was-validated');

    validate.style.opacity = 0;
    validate.textContent = '';
    if (!form.checkValidity()) {
      form.querySelector(":invalid").scrollIntoView({ block: 'center', behavior: 'smooth' });
      return;
    }
    submitBtn.disabled = false;
    // console.log(this.state.message);
    fetch("https://mad-up.biz/Back-end/contactRequest", {
      method: "POST",
      body: JSON.stringify({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        request: "DEMO",
        company_name: this.state.company_name,
        country: this.state.country,
        region: this.state.region,
        subject: this.state.subject,
        message: ""
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((data) => {
      validate.scrollIntoView({ block: 'center', behavior: 'smooth' });
      if (data.status === 200) {
        // console.log(data);
        validate.textContent = this.state.data.validate.success;
        validate.classList.add("submit");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
        // document.querySelector(".message").textContent = this.state.validate.valid
        form.classList.remove('was-validated');

        this.setState({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          company_name: "",
          country: "",
          region: "",
          subject: "",
          validate: {},
          isVerified: false
        });

        document.querySelector('#invalidCheck').checked = false;
      }
      if (data.status === 400 || data.status === 422) {
        console.log('error 400 ou error 422')
        validate.textContent = this.state.data.validate.error;
        validate.classList.remove("submit");
        validate.classList.add("error");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      }
      if (data.status === 409) {
        console.log("error 409")
        validate.textContent = this.state.data.validate.error;
        validate.classList.add("submit");
        validate.style.opacity = 1;
        validate.style.animation =
          "slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      }
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSubject = (value) => {
    this.setState({ subject: value });
  };

  render() {
    return (
      <>
        <Meta meta={this.state.meta} />
        <MDBAnimation type="fadeIn">
          {/* <Breadcrumb title={this.state.data.breadcrumb} /> */}

          <BigTitle
            text=""
            title={this.state.bigtitle.title}
            span={this.state.bigtitle.span}
            direct="left"
          />
          <div className="container contact">
            <form
              className="needs-validation"
              id="someForm"
              onSubmit={this.submitHandler}
              noValidate
            >
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.lastname}
                    name="lastname"
                    onChange={this.changeHandler}
                    type="text"
                    label={this.state.label.lastname}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.firstname}
                    name="firstname"
                    onChange={this.changeHandler}
                    type="text"
                    label={this.state.label.firstname}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="12">
                  <MDBInput
                    value={this.state.company_name}
                    name="company_name"
                    onChange={this.changeHandler}
                    type="text"
                    id=""
                    label={
                      this.state.label.company_name
                    }
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.email}
                    onChange={this.changeHandler}
                    type="email"
                    name="email"
                    label={this.state.label.email}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.phone}
                    onChange={this.changeHandler}
                    type="phone"
                    name="phone"
                    label={this.state.label.phone}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.country}
                    onChange={this.changeHandler}
                    type="country"
                    name="country"
                    label={this.state.label.country}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput
                    value={this.state.region}
                    onChange={this.changeHandler}
                    type="region"
                    name="region"
                    label={this.state.label.region}
                    required
                  >
                    <div className="invalid-feedback">
                      {this.state.validate.invalid}
                    </div>
                    <div className="valid-feedback">
                      {this.state.validate.valid}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="6">
                  <MDBDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <MDBDropdownToggle caret color="#757575">
                      {this.state.subject || this.state.label.placeholderSubject}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.call)}>
                        {this.state.label.call}
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.demo)}>
                        {this.state.label.demo}
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={() => this.setSubject(this.state.label.visio)}>
                        {this.state.label.visio}
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCol>
              </MDBRow>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <div className="custom-control custom-checkbox pl-4">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="invalidCheck"
                    >
                      {this.state.label.checkbox}
                    </label>
                    <div className="invalid-feedback">
                      {this.state.validate.checkbox}
                    </div>
                  </div>
                </MDBCol>
                <MDBCol md="12" className="mb-5">

                  <p className="recaptcha"></p>
                  {/* <p className="message"></p> */}
                </MDBCol>
              </MDBRow>

              <button
                type="submit"
                className="button button_submit button_blue"
              >
                <span className="hyphen"></span>
                <span className="button_text">
                  {this.state.label.button_text}
                </span>
              </button>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
              <div className="form-validate">
              </div>
              <MDBRow><MDBCol md="12" className="mb-5"></MDBCol></MDBRow>
            </form>
          </div>
        </MDBAnimation>
      </>
    );
  }
}

export default withRouter(DemoRequest);
