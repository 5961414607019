import React, { useState, useEffect } from "react";
import "./DetailEquipe.scss";



const DetailEquipe =(props)=>{
return(
  <>
    <div className="container equipeDetail">
        <div className="flexequipe"> 
        <img src={props.data?.image} alt="equipe"/>
       
        <div className="detailText">
          <h3>{props.data.Name}</h3>
          <span>{props.data.profession}</span>
          <p>{props.data.personalite}</p>
        </div>
        </div>

    </div>
  </>
)

}


export default DetailEquipe ;

























































