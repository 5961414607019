import React, { useState, useEffect } from "react";
import "./FixedButtons.scss";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/ButtonDemo.json";
//import { FaCalendarAlt } from "@react-icons/all-files/fa/FaCalendarAlt";
import { FaCalendarPlus } from "@react-icons/all-files/fa/FaCalendarPlus";
import { HashLink } from 'react-router-hash-link';
import {TiThumbsUp} from "@react-icons/all-files/ti/TiThumbsUp";
import { BiMessageDots } from "react-icons/bi";
const FixedButtons = (props) => {
    const lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    const scrollToTop = () => window.scrollTo(0, 0);

    return (
        <div className="fixed_buttons">
          
          <Link
                        to={`/${lang}${data.demo.SLUG}`}
                        className="fixed_button"
                        onClick={scrollToTop()}
                        target="_blank"
                    >
                       <FaCalendarPlus/>
                       <span  className="text">{data.demo.text}</span> 
                    </Link>
                   

                         <Link
                        to={`/${lang}${data.contact.SLUG}`}
                        className="fixed_button"
                        onClick={scrollToTop()}
                        target="_blank"
                    >
                       <BiMessageDots/>
                       <span  className="text">{data.contact.text}</span>
                    </Link>
                 
                    

                    <HashLink 
                        smooth to="#socialMedia"
                        className="fixed_button"
                        onClick={scrollToTop()}>
                   
                       <TiThumbsUp/>
                       <span  className="text">{data.suivi.text}</span>
                   
                    </HashLink>
            

        </div>
    );
};

export default withRouter(FixedButtons);
