import React from "react";

function InsertHTML({ html }) {
    const thisIsMyCopy = html;
  
    return (
        <div id="article_description">
            <div
                className="content"
                dangerouslySetInnerHTML={{ __html: thisIsMyCopy }}
            ></div>
        </div>
    );
}

export default InsertHTML;
