import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../../Components/Breadcrumb";
import BigTitle from "../../BigTitle";
import Button from "../../Button";
import * as dataJSON from "../../../Data/Applications.json";
import { withRouter } from "react-router-dom";

import "../Applications.scss";
import Meta from "../../Meta";

const PreOp = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
  
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);

    return (
        <>
       <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                <Breadcrumb title={data.preop.breadcrumb} />
                
                <div className="container sub-application">
                    <div className="grid-2">
                        
                        <div className="text  dotted_right">
                        <div className="title">{data.preop.title}</div>
                        <BigTitle
                            text=""
                            title=""
                            small={data.preop.bigtitle.span}
                            direct="left"
                        />
                        <div></div>
                            {data.preop.text.map((p) => (
                                <p>{p}</p>
                            ))}
                        </div>
                        <div>
                            <div className="img">
                            <video controls width="600" poster={data.preop.video.img}>

                                <source src={data.preop.video.SLUG} 
                                        type="video/webm"/>

                                <source src={data.preop.video.SLUG} 
                                        type="video/mp4"/>

                                Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                            
                        </div>
                    </div>
                   
                </div>
                {/* <ModalApp text={data.preop.button.text} src={data.preop.button.SLUG} poster={data.preop.button.img}/> */}
                {/* <BigTitle
                    text=""
                    title=""
                    span={data.preop.protocole.bigtitle.span}
                    direct="right"
                />
                <div className="container sub-application">
                    <div className="text-padding  dotted_left">
                        <p>
                            <b>{data.preop.protocole.text[0].bold} </b>

                            {data.preop.protocole.text[0].normal}
                        </p>
                    </div> */}
                   
                    {/* <Button
                        text={data.preop.button.text}
                        src={`/${lang}${data.preop.button.SLUG}`}
                        color="blue"
                    /> */}
                   
            </MDBAnimation>
        </>
    );
};
export default withRouter(PreOp);
