import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../Components/Breadcrumb";
import BigTitle from "../../Components/BigTitle";
import Button from "../../Components/Button";
import "./APropos.scss";
import { withRouter } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import * as dataJSON from "../../Data/Histoire.json";
import Meta from "../../Components/Meta";
import TestimonialEquipe from "../../Components/TestimonialEquipe/TestimonialEquipe";
import DetailEquipe from "../../Components/DetailsEquipe/DetailEquipe";
import TestimonialDetailEquipe from "../../Components/TestimonialDetailEquipe/TestimonialDetailEquipe"
const APropos = (props) => {
    let lang = props.match.params.lang;

    const [data, setData] = useState(dataJSON.default[lang]);
    const[selectedPerson, setSelectedPerson]=useState(1);
    const [opacity, setOpacity]=useState({"filter":"opacity(100%)"})

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
   
   const selectPerson=(name)=>{
    const index=data.equipes.items.findIndex((p)=>{
        return name===p.Name;
    })
    setSelectedPerson(index);
}

    const updateSelectedPerson=(index)=>{
        //herve 6
        if(index === 6) {
            setSelectedPerson(1);
        }
        //milos 7 

        if(index === 7 ) {
            setSelectedPerson(2);
        }
        //guy 8 1
        if(index === 8 || index === 1) {
            setSelectedPerson(3);
        }
        //joris 9 2
        if(index === 9 || index === 2) {
            setSelectedPerson(4)
        }

        //alexis 10 3
        if(index === 10 || index === 3) {
            setSelectedPerson(5);
        }
        //delis 11 4 

        if(index === 11 || index === 4) {
            setSelectedPerson(0);
          
        }
    }
    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">

                 <div className="Aproposimage container">
                    <div className="card">
                        <Link smooth to={`/${lang}/Qui-sommes-nous#valeur`} 
                       
                        ><img src={data.Img.image_full_url1} alt="" className="card-image" /></Link>
                        <div className="card-title">{data.Img.textimg1}</div>
                   
                    </div>


                    <div className="card">
                        <Link smooth to={`/${lang}/Qui-sommes-nous#expert`}
                        
                        ><img src={data.Img.image_full_url3} alt="" className="card-image" /></Link>
                        
                            <div className="card-title1">{data.Img.textimg2}</div>

                        
                    </div>


                    <div className="card">
                        <Link smooth to={`/${lang}/Qui-sommes-nous#equipe`}
                        ><img src={data.Img.image_full_url2} alt="" className="card-image" /></Link>
                        <div className="card-title2">{data.Img.textimg3}</div>
                    </div>

                </div>
                <div className="bfrexpert">
                 <div className="margeApropos" id="valeur">

                        <BigTitle
                            text={null}
                            title=""
                            span={data.ADN.bigtitle.span}
                            small={data.ADN.bigtitle.small}
                            direct="left"
                        />

                        <div className="container">
                            <div className="grid">
                                <div className="dotted_right">
                                    <h2>{data.ADN.h21.text}</h2>
                                    <p>{data.ADN.textleft1}</p>
                                   
                                    <ul>
                                        <li><strong>{data.ADN.li1leftBold}</strong> {data.ADN.lileft1}</li>
                                        <li><strong>{data.ADN.li2leftBold}</strong> {data.ADN.lileft2}</li>
                                        <li><strong>{data.ADN.li3leftBold}</strong> {data.ADN.lileft3}</li>
                                  
                                    </ul>
                                   
                                </div>
                                <div className="dotted_right">
                                    <h2>{data.ADN.h22.text}</h2>
                                    
                                        <p>{data.ADN.textcenter1}</p>
                                   
                                </div>

                                <div className="dotted_right">
                                    <h2>{data.ADN.h23.text}</h2>
                                    <ul>
                                        <li><strong>{data.ADN.li1rightBold}</strong> {data.ADN.liright1}</li>
                                        <li><strong>{data.ADN.li2rightBold}</strong> {data.ADN.liright2}</li>
                                        <li><strong>{data.ADN.li3rightBold}</strong> {data.ADN.liright3}</li>
                                  
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="margeApropos" id="expert">

                        <BigTitle
                            text={null}
                            title=""
                            span={data.experts.bigtitle.span}
                            small={data.experts.bigtitle.small}
                            direct="right"
                        />

                        <div className="container">
                            <div className="grid-2">
                                <div>
                                    
                                    {data.experts.textleft.map((p) => (
                                        <p>{p}</p>
                                    ))}
                                </div>

                                <div>
                                <video  poster={data.experts.videoright.poster} controls className="videoDelis">
                                    <source src={data.experts.videoright.video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        </div>
                   

                  
                </div>
                <BigTitle
                            text={null}
                            title=""
                            span={data.equipes.title}
                            small=""
                            direct="left"
                        />
                <section className="testimonials  carouselDesktop" id="equipe">
                 
                    < DetailEquipe data={data.equipes.items[selectedPerson]} />
                    <TestimonialEquipe data={data.equipes} updateSelectedPerson={updateSelectedPerson} opacity={opacity} selectPerson={selectPerson}/>
                </section>

                <section className="DetailMobile">
                    <TestimonialDetailEquipe data={data.equipes}/>
                </section>
            </MDBAnimation>
        </>
    );
};

export default withRouter(APropos);
