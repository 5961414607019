import React, { useState, useEffect } from "react";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import { MDBAnimation } from "mdbreact";
import "./BlogCard.scss";
import styled from "styled-components";
import InsertHTML from "./InsertHTML";
import { Skeleton } from "@chakra-ui/core";
import { FacebookProvider, ShareButton } from "react-facebook";
import Meta from "../../Components/Meta";

const Social = styled.div`
    display: flex;
    i {
        font-size: 20px;
        border-radius: 5px;
        margin-left: 10px;
        color: #fff;
        width: 30px;
        height: 30px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms ease;
        border: 1px solid transparent;
        &.icon-facebook {
            background: #305b97;
        }

        &.icon-twitter {
            background: #34adeb;
        }

        &:hover {
            &.icon-facebook {
                background: #fff;
                color: #305b97;
                border: 1px solid #305b97;
            }
            &.icon-twitter {
                background: #fff;
                color: #34adeb;
                border: 1px solid #34adeb;
            }
        }
    }
`;

const BlogCard = (props) => {
  
    const [loading, setLoading] = useState(true);

    const lang = props.match.params.lang;
    const pathname = props.location.pathname;
    const [meta, setMeta] = useState({ title: null, description: null });
    const [blog, setBlog] = useState([]);

    useEffect(
        function promiseArticles() {
            const madUpService = new MadUpService();
            madUpService.getArticle(lang, props.id).then((res) => {
                setBlog(res);
                setMeta({
                    title: res.title,
                    description: res.summary,
                    image: res.image,
                    url: `https://mad-up.com${pathname}`,
                });
                setLoading(false);
            });

             return function cleanup() {
                setLoading(true);
    };
        },
        [pathname, props.id, lang]
    );

    return (
        <>
           
            <Meta meta={meta} />
                <MDBAnimation type="fadeIn">
                    <section className="article">
                        {loading ? (
                            <Skeleton my="10px" id="blog_skeleton" />
                        ) : (
                            <>
                                <div className="article_photo">
                                    <img src={blog.image} alt={blog.title} />
                                </div>
                                <div className="article_data">
                                    <p>
                                        <span className="article_category">
                                            {blog.slug}
                                        </span>
                                        <span>| {blog.date}</span>
                                    </p>
                                    <Social>
                                        <FacebookProvider appId="3644228205629367">
                                            <ShareButton
                                                href={`https://mad-up.com${pathname}`}
                                                className="facebookfeed"
                                            >
                                                <i className="icon-facebook"></i>
                                            </ShareButton>
                                        </FacebookProvider>

                                        <a
                                            href={`https://twitter.com/share?text=${blog.title}&url=https://mad-up.com${pathname}`}
                                            title="Partagez le lien sur Twitter"
                                          
                                            rel="noopener noreferrer"
                                            target="_parent"
                                        >
                                            <i className="icon-twitter"></i>
                                        </a>
                                    </Social>
                                </div>
                                <h3>{blog.title}</h3>
                                <InsertHTML html={blog.html} />
                            </>
                        )}
                    </section>
                   
                </MDBAnimation>
          
        </>
    );
};

export default withRouter(BlogCard);
