import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`

  margin:auto;
  width:100px;
  right:80px;
  height: 81px;
  position: fixed;
  top: 0;
  z-index: 20;
  display: none;
  padding: 20px 11px;
  text-aling:center;
  background-color:#15acf2;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    right:140px;
    height:88px;
    
    
  }
  @media(max-width: 768px){
    width: 70px;
    height: 81px;
    right: 17px;
  }
  div {
    margin:auto;
    width: 68px;
    height: 6.8px;
    background-color: #fff;
    border-radius: 10px;
    transform-origin: 10px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
    @media screen and (max-width:768px){
      width:42px;
      height:6.5px;
      transform-origin: 2px;
    }
  }
`;

const Burger = (props) => {
  const [open, setOpen] = useState(false);
  const style = {
    overflow: "visible",
    cursor: "pointer",
    // disable touch highlighting on devices
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
};
  
  return (
    <>
      <StyledBurger open={open} 
        onClick={() => setOpen(!open)} 
        style={{ cursor: "pointer" }}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} lang={props.lang}/>
    </>
  )
}

export default Burger;