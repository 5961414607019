import React, { useState, useCallback } from "react";
import NeumorphicButton from "../NeumorphicButton/NeumorphicButton";
import "./NeumorphicMenu.scss";
export default function NeumorphicMenu({ children }) {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen((state) => !state);
    }, []);

    return (
        <Wrapper open={open}>
            <NeumorphicButton onItemClick={toggleOpen} open={open} />
            <Content open={open} onItemClick={toggleOpen}>
                {children}
            </Content>
        </Wrapper>
    );
}

function Wrapper({ children, open }) {
    return (
        <div
            className={
                open ? "neumorphic-menu active" : "neumorphic-menu unactive"
            }
        >
            {children}
        </div>
    );
}

function Content({ onItemClick, open, children }) {
    return (
        <div
            onClick={() => {
                onItemClick(!open);
            }}
        >
            {children}
        </div>
    );
}
