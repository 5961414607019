import React, { useState, useEffect } from "react";
import "./Partners.scss";
import Button from "../Button/";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
export default (props) => {
    const lang = props.lang
   
    const [partner, setPartner] = useState(props.data.partner1);
  
    useEffect(() => {
        setPartner(props.data.partner1)
     
    }, [lang, props])

    const [line, setline] = useState("100px");
    const allClass = document.querySelectorAll(".partners_item");
    const addActiveClass = (e) => {
        let target = e.target;
      
       allClass.forEach((item) => {
        item.classList.remove("active")
       
    });
   
        target.classList.add("active")
    }
   

    return (
        <>
        <section className="partners">
            <div
                className="partners_left"
                style={{ background: `url(${partner.image}` }}
            ></div>
            <div className="partners_center">
                <div className="clubs-sportifs">
                    <h5>{props.data.badge}</h5>
                    <h3>{partner.title}</h3>
                    <div className="text">
                        {partner.texts.map((text, id) => {
                            return <p key={id}>{text}</p>;
                        })}

                        {/* <div className="partners_logo"> */}
                            {/* <img src="https://mad-up.gyper.site/img/pages/home/paris.png" alt=""/> */}
                        {/* </div> */}
                        {/* <Button
                    text={partner.button.text}
                    src={`/${lang}${partner.button.SLUG}`}
                    color="grey"
                /> */}
                    </div>
                </div>

            </div>
                                
            <div className="partners_right">
                <nav className="partners_list">
                    <div className="partners_line" style={{ top: line }}></div>
                    <div
                        className="partners_item active"
                        onClick={(e) => {
                            setPartner(props.data.partner1);
                            setline("100px");
                            addActiveClass(e);
                        }}
                    >
                        {props.data.partner1.link}
                    </div>
                    
                    <div
                        className="partners_item"
                        onClick={(e) => {
                            setPartner(props.data.partner2);
                            setline("229px");
                            addActiveClass(e)
                        }}
                    >
                        {props.data.partner2.link}
                    </div>
                </nav>
               
            </div>
             
            </section>

            <div className="clients">
                    <ClientSlider data={partner.clients} />

                </div>             
            </>
            
    );
};

function ClientSlider(props) {
    const responsive = {
        superLargeDesktop: {
            slidesToSlide: 5,
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 4,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2,
        },
    };

    return (
        <>
            <Carousel
                responsive={responsive}
                showDots
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                useKeyboardArrows={false}
                className=" container multi-item-carousel client-slider"
            >
                {props.data.items.map((item, id) => {
                    return (
                        <div key={id}>
                            <a href={item.url} target="_blanc">
                                <img src={item.image} alt={item.url} />
                            </a>
                        </div>
                    );
                })}
            </Carousel>
        </>
    );
}