import React from "react";
import "./Breadcrumb.scss";

export default ({ title, link }) => {
    return (
        <section className="bradcrumb">
            <h1>{title}</h1>
            <h4>{link}</h4>
        </section>
    );
};
