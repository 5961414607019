import React, { useState } from "react";
import "./TooltipsBonhomme.scss";

const Tooltip = () => {
   
    return (
       
        <div
            className={"tool "}>
            <div className="tooltip_circle waves "></div>    
        </div>

        
    );
};

export default Tooltip;
