import React, { useEffect, Suspense } from "react";
import "./TooltipsBonhomme.scss";
import Tooltip from "./TooltipBonhomme";
import 'react-multi-carousel/lib/styles.css';
import BigTitle from "../BigTitle";
import { withRouter } from "react-router-dom";
import { gsap } from "gsap";
import ScrollEffect from "../ScrollEffect/ScrollEffect";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Modal from "./Modal";

gsap.registerPlugin(ScrollTrigger);


const TooltipsBonhomme = (props) => {
  /*** Différentes configurations de ScrollTrigger pour différentes tailles d'écran (media queries) ***/

  useEffect(() => {
    ScrollTrigger.matchMedia({

      // desktop
      "(min-width: 768px)": function () {
        // stopper  l'animations et le ScrollTriggers pour les ecrans plus 1100px px (desktop)
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".layer-content",
            scrub: true,
            pin: true,
            start: "center 55%",
            end: "2600px",
            pinspacer: true,
            markers: false,
          }
        });

        tl.from(
          ".Block",
          { y: 0, autoAlpha: 0, stagger: 1 }, 0)
          .to(
            ".Block",
            {
              y: 0,
              autoAlpha: 0,
              stagger: 1



            },
            1
          );
        tl.from(
          ".tooltip_circle",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".tooltip_circle",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,



          },
          0.5
        );
        tl.from(
          ".color",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".color",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          1
        );
      },
      // mobile
      "(max-width: 767px)": function () {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".layer-content-mobile",
            scrub: true,
            pin: true,
            start: "top 0",
            end: "1200px",
            pinspacer: true,
            markers: false,
          }
        });

        tl.from(
          ".Block",
          { y: 0, autoAlpha: 0, stagger: 1 }, 0)
          .to(
            ".Block",
            {
              y: 0,
              autoAlpha: 0,
              stagger: 1
            },
            1
          );
        tl.from(
          ".tooltip_circle",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".tooltip_circle",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          0.5
        );
        tl.from(
          ".color",
          { y: 0, autoAlpha: 0, stagger: 1 },
          0
        ).to(
          ".color",
          {
            y: 0,
            autoAlpha: 0,
            stagger: 1,
          },
          1
        );
      }

    });
  }, []);
  const lang = props.match.params.lang;
  const data = props.data;


  return (
    <>
     
      <div className="layer-content-mobile">
        <BigTitle
          text={data.bigtitle.text}
          title={data.bigtitle.title}
          span={data.bigtitle.span}
          direct="left"
        />
      
        <div className="layer-content">
       
            <div className="tools bg-blue ">
           
            <img src={data.coverImage} alt="bonhomme 3d" className="imgBonhomme" />
          
              {data.view.map(({ id}) => {
                return (
                  <Tooltip
                    key={id}
                    id={id}
                   

                  />
                );
              })}
              
              <div className="card-titleBonhomme">{data.bigtitle.span}
              </div>

              <ScrollEffect />
            
              <div className="Block" id="01">
                <div className="tools">
                  <div className="tool ">
                    <div className="tooltip_block">
                      <Modal slug={data.view[0].slug} text={data.view[0].textBtn}
                       src={`/${lang}${data.view[0].src}`} title={data.view[0].title} span={data.view[0].span}
                       legend={data.view[0].legend} textSpan1={data.view[0].textSpan1} textSpan2={data.view[0].textSpan2} textSpan3={data.view[0].textSpan3} membre1={data.view[0].membre1} membre2={data.view[0].membre2}
                      membre3={data.view[0].membre3} membre4={data.view[0].membre4} membre5={data.view[0].membre5} membre6={data.view[0].membre6 }  category1={data.view[0].category1} category2={data.view[0].category2} category3={data.view[0].category3}
                      firstName={data.view[0].firstName} lastName={data.view[0].lastName} email={data.view[0].email} textSubmit={data.view[0].textSubmit} textSpan={data.view[0].textSpan} reponse={data.view[0].reponse} error={data.view[0].error} errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="02">
                <div className="tools">
                  <div className="tool ">
                    <div className="tooltip_block2">
                      <Modal slug={data.view[1].slug} text={data.view[1].textBtn} src={`/${lang}${data.view[0].src}`} title={data.view[1].title} span={data.view[1].span} 
                      legend={data.view[1].legend} textSpan1={data.view[1].textSpan1} textSpan2={data.view[1].textSpan2} textSpan3={data.view[1].textSpan3} membre1={data.view[1].membre1} membre2={data.view[1].membre2}
                      membre3={data.view[1].membre3} membre4={data.view[1].membre4} membre5={data.view[1].membre5} membre6={data.view[1].membre6 }  category1={data.view[1].category1} category2={data.view[1].category2} category3={data.view[1].category3}
                      firstName={data.view[1].firstName} lastName={data.view[1].lastName} email={data.view[1].email} textSubmit={data.view[1].textSubmit} textSpan={data.view[1].textSpan} reponse={data.view[1].reponse} error={data.view[0].error} errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="Block" id="03">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block3">
                      <Modal slug={data.view[2].slug} text={data.view[2].textBtn} 
                      src={`/${lang}${data.view[0].src}`} title={data.view[2].title} span={data.view[2].span}
                      legend={data.view[2].legend} textSpan1={data.view[2].textSpan1} textSpan2={data.view[2].textSpan2} textSpan3={data.view[2].textSpan3} membre1={data.view[2].membre1} membre2={data.view[2].membre2}
                      membre3={data.view[2].membre3} membre4={data.view[2].membre4} membre5={data.view[2].membre5} membre6={data.view[2].membre6 }  category1={data.view[2].category1} category2={data.view[2].category2} category3={data.view[2].category3}
                      firstName={data.view[2].firstName} lastName={data.view[2].lastName} email={data.view[2].email}  textSubmit={data.view[2].textSubmit} textSpan={data.view[2].textSpan} reponse={data.view[2].reponse} error={data.view[0].error}  errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Block" id="04">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block4">
                      <Modal slug={data.view[3].slug} text={data.view[3].textBtn} 
                      src={`/${lang}${data.view[0].src}`} title={data.view[3].title} span={data.view[3].span}
                      legend={data.view[3].legend} textSpan1={data.view[3].textSpan1} textSpan2={data.view[3].textSpan2} textSpan3={data.view[3].textSpan3} membre1={data.view[3].membre1} membre2={data.view[3].membre2}
                      membre3={data.view[3].membre3} membre4={data.view[3].membre4} membre5={data.view[3].membre5} membre6={data.view[3].membre6 }  category1={data.view[3].category1} category2={data.view[3].category2} category3={data.view[3].category3}
                      firstName={data.view[3].firstName} lastName={data.view[3].lastName} email={data.view[3].email} textSubmit={data.view[3].textSubmit} textSpan={data.view[3].textSpan} reponse={data.view[3].reponse} error={data.view[0].error}  errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="06">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block6 ">
                      <Modal slug={data.view[5].slug} text={data.view[5].textBtn} 
                      src={`/${lang}${data.view[0].src}`} title={data.view[5].title} span={data.view[5].span} 
                      legend={data.view[5].legend} textSpan1={data.view[5].textSpan1} textSpan2={data.view[5].textSpan2} textSpan3={data.view[5].textSpan3} membre1={data.view[5].membre1} membre2={data.view[5].membre2}
                      membre3={data.view[5].membre3} membre4={data.view[5].membre4} membre5={data.view[5].membre5} membre6={data.view[5].membre6 }  category1={data.view[5].category1} category2={data.view[5].category2} category3={data.view[5].category3}
                      firstName={data.view[5].firstName} lastName={data.view[5].lastName} email={data.view[5].email} textSubmit={data.view[5].textSubmit} textSpan={data.view[5].textSpan} reponse={data.view[5].reponse} error={data.view[0].error} errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Block" id="05">
                <div className="tools">
                  <div className="tool">
                    <div className="tooltip_block5">
                      <Modal slug={data.view[4].slug} text={data.view[4].textBtn} 
                      src={`/${lang}${data.view[0].src}`} title={data.view[4].title} 
                      span={data.view[4].span} 
                      legend={data.view[4].legend} textSpan1={data.view[4].textSpan1} textSpan2={data.view[4].textSpan2} textSpan3={data.view[4].textSpan3} membre1={data.view[4].membre1} membre2={data.view[4].membre2}
                      membre3={data.view[4].membre3} membre4={data.view[4].membre4} membre5={data.view[4].membre5} membre6={data.view[4].membre6 }  category1={data.view[4].category1} category2={data.view[4].category2} category3={data.view[5].category3}
                      firstName={data.view[4].firstName} lastName={data.view[4].lastName} email={data.view[4].email} textSubmit={data.view[4].textSubmit} textSpan={data.view[4].textSpan} reponse={data.view[4].reponse} error={data.view[0].error}  errorMail={data.view[0].errorMail} errorForm={data.view[0].errorForm}
                      />
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="navmenu">
              
                <span className="hr"></span>
                
                <div className="circlestep">
                  <span className="color"></span>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div>
                  {/* <div className="circlestep-01"><span className="color"></span></div>
                  <div className="circlestep-01"><span className="color"></span></div> */}
               
                </div>
                
              </div>
              
            </div>
         
           
        </div>
       
      </div>


    </>
  );
};
export default withRouter(TooltipsBonhomme);
