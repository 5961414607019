import React from "react";
import BlogList from "./BlogList";
import LatestArticle from "./LatestArticle";
import { withRouter } from "react-router-dom";

const SideBar = (props) => {
    return (
        <aside className="blog-sidebar">
             <BlogList
                onListSelected={(slugId) => {
                    props.history.push(`/fr/actualite/${slugId}`);
                }}
            />
            <LatestArticle
                onItemSelected={(id, slugId) => {
                    props.history.push(`/fr/actualite/${slugId}/${id}`);
                }}
            />
           
        </aside>
    );
};

export default withRouter(SideBar);
