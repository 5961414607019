import React from "react";
import "./Map.scss";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Grid from "../Grid/Grid";
import { withRouter } from "react-router-dom";
import './MapPoints.scss'
const MapPoints = (props) => {
    const selectedPark = props.selectedPark;

    const scrollToTop = () => window.scrollTo(0, 300);

    return (
        <>
            {props.points.map((point) => (
                <Marker
                    key={point.id}
                    position={{
                        lat: point.location.lat,
                        lng: point.location.lng,
                    }}
                    onClick={() => {
                        console.log("point", point);
                        props.onItemClick(point);
                    }}
                    icon={{
                        url: `https://mad-up.com/Map/pin-bold.png`,
                    }}
                />
            ))}

            {selectedPark && (
                <InfoWindow
               
                    onCloseClick={() => {
                        props.onItemClick(null);
                        scrollToTop();
                    }}
                    position={{
                        lat: selectedPark.location.lat,
                        lng: selectedPark.location.lng,
                    }}
                >
                    <section className="map_point">
                        <div className="map_point_image" style={{background: `url(${selectedPark.image.data.full_url})`}}>
                          
                        </div>
                        <Grid col={2} gap={0}>
                            <div className="map_point_title">
                                {selectedPark.translations[0].name}
                            </div>
                            <div className="map_point_logo">
                                <img
                                    src={selectedPark.logo.data.full_url}
                                    alt={selectedPark.translations[0].name}
                                />
                            </div>
                        </Grid>

                        <ul className="map_point_contact">
                            <div className="icon_text">
                                <i className="icon-pin"></i>
                                <a
                                        href={`https://www.google.com.ua/maps/search/${selectedPark.translations[0].address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {selectedPark.translations[0].address}
                                    </a>
                            
                            </div>
                            {/* <div className="icon_text">
                                <i className="icon-pin-light"></i>
                                <p>Voir sur Google Maps</p>
                            </div> */}
                            {selectedPark.email && (
                                <div className="icon_text">
                                    <i className="icon-contact"></i>
                                    <a href={`mailto:${selectedPark.email}`}>{selectedPark.email}</a>
                                </div>
                            )}

                            {selectedPark.phone && (
                                <div className="icon_text">
                                    <i className="icon-phone"></i>
                                    <a href={`tel:${selectedPark.phone}`}>
                                        {selectedPark.phone}
                                    </a>
                                </div>
                            )}

                            {selectedPark.website && (
                                <div className="icon_text">
                                    <i className="icon-link"></i>
                                    <a
                                        href={selectedPark.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {selectedPark.website}
                                    </a>
                                </div>
                            )}
                        </ul>
                    </section>
                </InfoWindow>
            )}
        </>
    );
};

export default withRouter(MapPoints);
