import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
//import Breadcrumb from "../../../Components/Breadcrumb";
import BigTitle from "../../BigTitle";
import * as dataJSON from "../../../Data/Applications.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Meta";
import "../Applications.scss";
//import ModalApp from "../../ModalVideo/ModalVideo";
const Rehub = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 
  
    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
        <Meta meta={meta} />
        <MDBAnimation type="fadeIn">
            {/* <Breadcrumb title={data.rehub.breadcrumb} /> */}
            {/* <BigTitle
                text=""
                title=""
                span={data.rehub.bigtitle.span}
                direct="left"
            /> */}

            <div className="container sub-application">
                <div className="grid-2">
                <div className="text dotted_right marge">
                    <div className="title">{data.rehub.title}</div>
                    <BigTitle
                        text=""
                        title=""
                        small={data.rehub.bigtitle.span}
                        direct="left"
                    />
                        {data.rehub.text.map((p) => (
                            <p>{p}</p>
                           
                        ))}
                    </div>
                    <div>
                        <div className="img">
                        <video controls width="600" poster={data.rehub.video.img}>

                                <source src={data.rehub.video.SLUG} 
                                        type="video/webm"/>

                                <source src={data.rehub.video.SLUG} 
                                        type="video/mp4"/>

                                Sorry, your browser doesn't support embedded videos.
                                </video>
                        </div>
                        
                    </div>
                   
                </div>
                {/* <ModalApp text={data.rehub.button.text} src={data.rehub.button.SLUG} poster={data.rehub.button.img}/> */}
            </div>
            {/* <BigTitle
                text=""
                title=""
                span={data.rehub.protocole.bigtitle.span}
                direct="right"
            />
            <div className="container sub-application">
                <div className="text-padding  dotted_left">
                    {data.rehub.protocole.text.map((item) => {
                        console.log("item", item);
                        return (
                            <p>
                                <b>{item.bold}</b> {item.normal}
                            </p>
                        );
                    })}
                </div> */}
                <div>
                {/* <Button
                    text={data.rehub.button.text}
                    src={`/${lang}${data.rehub.button.SLUG}`}
                    color="blue"
                /> */}
            
            </div>
        </MDBAnimation>
        </>
    );
};

export default withRouter(Rehub);
