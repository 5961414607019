import React, { useState, useEffect, useCallback }  from "react";
import {
    GoogleMap,
  
    LoadScript,
} from "@react-google-maps/api";
import MapCategory from "./MapCategory";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import MapPoints from "./MapPoints";
import "./Map.scss";
import { FcMinus } from "react-icons/fc";
import { DiAptana } from "react-icons/di";

function Locate({ panTo, onItemClick }) {
    return (
        <button
            className="locate"
            onClick={() => {
               
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                      
                        onItemClick({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                       
                        panTo({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    () => null
                );
            }}
        >
            <img src="https://mad-up.com/Map/compass.svg" alt="compass" />
        </button>
    );
}

const containerStyle = {
    width: "100%",
    height: "100vh",
};


const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

function Grid({ children }) {
    return <div className="grid-maps">{children}</div>;
}



const GOOGLE_KEY = "AIzaSyCQJJ-sI_pyEn8_4mczWFAXh8hs5K8Uueo";

function Maping(props) {
   
    const [activeIndex, setActiveIndex] = useState(1);
    const [points, setPoinst] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedPark, setSelectedPark] = useState(null);
    const [geolocation, setGeolocation] = useState({
        lat: 48.8688974,
        lng: 2.2585666,
    }) 
    
    const [center, setCenter] = useState({
        lat: 48.8688974,
        lng: 2.2585666,
    })
    const toggleActiveIndex = useCallback((id) => {
        setActiveIndex(id);
    }, []);
    const togglePoint = useCallback((id) => {
        setSelectedPark(id);
    }, []);
    const getGeolocation = useCallback((obj) => {
        setGeolocation(obj)
        console.log("onItemClick", obj);
    }, []);

    useEffect(() => {
        const goal = geolocation.lng;
        function mapresult(arr) {
            if ( arr.length > 0) {
                const count = arr.map((e) => {
                    return e.location.lng
                }).sort()
                console.log("countsLAT", count);
                return count
            }
            return [1]
        }
        const counts = mapresult(points)
    
        const nearestBelow = (input, lookup) => lookup.reduce((prev, curr) => input >= curr ? curr : prev);
       
        
        const result = points.filter((arr) => arr.location.lng === nearestBelow(goal, counts));
      
        if (result.length > 0) {
            setCenter({
                lat: result[0].location.lat,
                lng: result[0].location.lng
            })
        }

    }, [points, geolocation.lng]);


    useEffect(() => {
        const madUpService = new MadUpService();
        madUpService.getPoints(activeIndex, props.lang).then((res) => {
            // console.log("res.data", res.data);
            setPoinst(res.data);
        });
    }, [activeIndex, props.lang]);

    const mapRef = React.useRef();
    const toggleOpen = useCallback((value) => {
        setOpen(!value)
    }, []);
    const onMapLoad = React.useCallback((map) => {
        // console.log({ map });
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(10);
    }, []);

    function updateData(value) {
        setActiveIndex(value);
    }

    return (
        <LoadScript googleMapsApiKey={GOOGLE_KEY} libraries={["places"]}>
            <Grid>
                <GoogleMap
                    id="map"
                    mapContainerStyle={containerStyle}
                    center={center}
                    options={options}
                    zoom={10}
                    onLoad={onMapLoad}
                 onClick={() => {
                    togglePoint(null)
                 }}
                >
                    <Locate 
                    panTo={panTo} 
                    onItemClick={getGeolocation}
                    />
                    <MapPoints
                    selectedPark={selectedPark}
                    onItemClick={togglePoint}
                        categoryID={activeIndex}
                        updateData={updateData}
                        points={points}
                    />
                </GoogleMap>
             
                    <section className={open ? "map_categories active" : "map_categories unactive"}>
                        <div
                            className={open ? "setting-button active" : "setting-button unactive"}
                            onClick={() => {
                                console.log("open", open);
                                setOpen(!open);
                            }}
                        >
                            {open ?  
                           
                                 <FcMinus />
                           
                            : <DiAptana />
                            }
                           
                        </div>

                        <div className="wrap_input">
                            <Search panTo={panTo} lang={props.lang}/>
                        </div>
                        <MapCategory
                        center={center}
                        points={points}
                            activeIndex={activeIndex}
                            onClick={() => {
                                setOpen(!open);
                                console.log("open", open);
                            }}
                            toggleOpen={toggleOpen}
                            open={open}
                            onItemClick={toggleActiveIndex}
                        />
                    </section>
               
            </Grid>
        </LoadScript>
    );
}
function Map(props) {
    const lang = props.match.params.lang;
  
    return (
       
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            
            <Maping lang={lang} />
          
            <script
                src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&libraries=places`}
            ></script>
        </div>
    );
}

function Search({ panTo, lang }) {
   
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 100 * 1000,
        },
    });

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    return (
        <div className="search">
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder={lang === "en" ? "Where?" : "Où?" }
                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" &&
                            data.map(({ id, description }) => (
                                <ComboboxOption key={id} value={description} />
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
}

export default withRouter(Map);
