import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/MentionsLegales.json";
import Meta from "../../Components/Meta";

const Rgpd = (props) => {
    let lang = props.match.params.lang;
  

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 

    useEffect(() => {
      
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
        <Meta meta={meta} />
            {lang === "fr" && (
                <MDBAnimation type="fadeIn">
                    <div className="test-page">
                        <h1>Rgpd FR</h1>
                    </div>
                </MDBAnimation>
            )}
            {lang === "en" && (
                <MDBAnimation type="fadeIn">
                    <div className="test-page">
                        <h1>Rgpd EN</h1>
                    </div>
                </MDBAnimation>
            )}
        </>
    );
};
export default withRouter(Rgpd);
