import React, { useState } from "react";
import {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from "mdbreact";
import "./Language.scss";
import { withRouter } from "react-router-dom";

const Language = (props) => {
    const url = props.location.pathname;
    const [lang, setlang] = useState(props.match.params.lang);

    const replaceLanguage = (lang) => {
        const langHMTM = window.document.querySelector("html");
        let result = url.replace(/^\/[a-z][a-z]/g, `/${lang}`);
        langHMTM.setAttribute("lang", lang);
        props.history.replace(result);
        setlang(lang);
    };

    return (
        <>
            <MDBDropdown className="language">
                <MDBDropdownToggle caret color="language">
                    <div className="dopdowrn language">{lang}</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                    <MDBDropdownItem text="FR">
                        <div
                            onClick={() => replaceLanguage("fr")}
                            className="drop-btn"
                        >
                            FR
                        </div>
                    </MDBDropdownItem>
                    <MDBDropdownItem text="ENG">
                        <div
                            onClick={() => replaceLanguage("en")}
                            className="drop-btn"
                        >
                            ENG
                        </div>
                    </MDBDropdownItem>
                    {/* <MDBDropdownItem text="ES">
                        <div
                            onClick={() => replaceLanguage("es")}
                            className="drop-btn"
                        >
                            ES
                        </div>
                    </MDBDropdownItem> */}
                </MDBDropdownMenu>
            </MDBDropdown>
            {/* <img src="https://mad-up.com/api/uploads/mad-up/originals/pictoMad-upAcademy.png" alt="pictoMadUp-Academy"></img> */}
        </>
    );
};

export default withRouter(Language);
