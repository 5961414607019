import React, { useState, useEffect } from "react";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import './MapCategory.scss'
const MapCategory = (props) => {
    const lang = props.match.params.lang;
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const madUpService = new MadUpService();
        madUpService.getMapCategory(lang).then((res) => {
            setCategories(res.data);
            console.log("getMapCategory", res.data);
        });
    }, [lang]);

    return (
        <>
            {categories.map((category, id) => {
                return (
                    <div
                        key={id}
                        className={
                            props.activeIndex === category.id
                                ? "map_category active"
                                : "map_category unactive"
                        }
                        onClick={() => {
                            props.toggleOpen(props.open);
                            props.onItemClick(category.id);
                        }}
                    >
                        {/* {console.log(category.translations[0].name)} */}

                        {category.translations[0].name}
                    </div>
                );
            })}
        </>
    );
};

export default withRouter(MapCategory);
