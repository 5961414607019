import React,{useState, useEffect} from "react";
import "./TooltipsBonhomme.scss"; 
import Button from "../Button/Button";
//import {Link} from "react-router-dom";
import styled from "styled-components";
import ModalBFR from "../ModalBFR/ModalBFR";

const Modal= ({ slug,title, span,text, src, legend,textSpan1,textSpan2,textSpan3 
    ,membre1,membre2,membre3,membre4,membre5, membre6, category1,category2, category3,
    firstName, lastName, email, textSubmit ,textSpan, reponse , error, errorMail, errorForm}) => {
    if (slug !== undefined) {
      //  console.log(slug);
        return (
            <>
            <h3>{title}</h3>
            <span>{span}</span>

                <img src={slug} alt={title}  className="imgBloc"/>
        
            <Button
                color="blue"
                text={textSpan}
                src={src}
          />
            {/* <ModalBFR legend={legend} textSpan1={textSpan1} textSpan2={textSpan2} 
            textSpan3={textSpan3}
            membre1={membre1}
            membre2={membre2}
            membre3={membre3}
            membre4={membre4}
            membre5={membre5}
            membre6={membre6}
            category1={category1}
            category2={category2}
            category3={category3}
            firstName={firstName}
            lastName={lastName}
            email={email}
            textSubmit={textSubmit}
            textSpan={textSpan}
            reponse={reponse}
            error={error}
            errorMail={errorMail}
            errorForm={errorForm}


            /> */}
         
            </>
            

           
        );
    } else return false;
};

const Ul = styled.ul`
span{
    color: rgb(6, 15, 30);
    font-size: 14px;
    font-weight: 700;
    // @media(max-width:768px){
    //     font-size:12px;
       
    // }

    // @media(max-width:1100px){
    //     font-size:20px;
       
    // }
}



`;


export default Modal;
