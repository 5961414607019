import React, { Component } from "react";
import { connect } from "react-redux";
import ModalExample from "../ModalExample/ModalExemple";

import { actions } from "../../ducks/modal.js";

import "./ModalVideo.scss";
class ModalVideo extends Component {
  render() {
    const {src}=this.props;
    const {poster}=this.props;
    const {text}=this.props;
    return (
      <div>
      <a className="buttonModal"
        onClick={() => {
          this.props.openModal({
            modalType: ModalExample,
            modalProps: {
              contenu: (
               <>
               
               <video width="900" poster={poster} controls>
                  <source src={src} type="video/webm" />
                  <source src={src} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
           
               </>
                  
              ),
      
            }
          });
        }}
        text={text}
      >
        <span className="hyphen"></span>
		    <span className="buttonModal_text">{text} </span>
	     </a>
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modalParams) => dispatch(actions.openModal(modalParams)),
    closeModal: () => dispatch(actions.closeModal())
  };
};
export default connect(null, mapDispatchToProps)(ModalVideo);
