import React from "react";
import Helmet from "react-helmet";

const Meta = ({meta}) => {
 
    return (
      
        <Helmet>
            <title>{meta.title}</title>
            <meta
                name="description"
                content={meta.description}
            />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={meta.title} />
            <meta
                itemprop="description"
                content={meta.description}
            />
            <meta
                itemprop="image"
                content={meta.image}
            />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={meta.canonical} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta
                property="og:description"
                content={meta.description}
            />
            <meta
                property="og:image"
                content={meta.image}
            />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={meta.title} />
            <meta
                name="twitter:description"
                content={meta.description}
            />
            <meta
                name="twitter:image"
                content={meta.image}
            />
        </Helmet>
    );
};

export default Meta;
