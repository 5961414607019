import React, { useState, useEffect } from "react";
import "./TestimonialDetailEquipe.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
import DetailEquipe from "../DetailsEquipe/DetailEquipe";

export default (props, {deviceType}) => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
     
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
     
    }
  };


  
  return (
    <>
    
  <Carousel
    additionalTransfrom={0}
    arrows
    autoPlaySpeed={3000}
    centerMode={false}
    className="container detailEquipe   multi-item-carousel "
    containerClass="carousel-container"
    dotListClass="custom-dot-list-style"
    draggable
    focusOnSelect
    removeArrowOnDeviceType={["tablet", "mobile"]}
    infinite
    itemClass="carousel-item-padding-40-px"
    keyBoardControl
    minimumTouchDrag={80}
    responsive={responsive}
    rewind={false}
    rewindWithAnimation={false}
    rtl={false}
    shouldResetAutoplay
    showDots={true}
    sliderClass=""
    slidesToSlide={1}
    swipeable
    deviceType={deviceType}
   

  >
        
        {props.data.items.map((item, id) => {
          return (
           <DetailEquipe  data={item}  key={id} />
          );
        })}
           
       
      </Carousel>
     
    </>
  )

}