import React, { Component } from "react";
import { connect } from "react-redux";
import ModalExampleProduit from  "../ModalExampleProduit/ModalExempleProduit";

import { actions } from "../../ducks/modal.js";

import "./ModalVideoProduit.scss";


class ModalVideoProduit extends Component {
  render() {
    const {src, button_text, poster, text}=this.props;
   
    return (
      <div>
      <a
        onClick={() => {
          this.props.openModal({
            modalType: ModalExampleProduit,
            modalProps: {
              contenu: (
               <>
               
               <video controls autoplay poster={poster}>
                  <source src={src} type="video/webm" />
                  <source src={src} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
           
               </>
                  
              ),
      
            }
          });
        }}
        text={text}
      >
        <div className="fonctionnement_link">
          {button_text}
          <span className="fonctionnement_link_arrow">
              &rarr;
          </span>
      </div>
	     </a>
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modalParams) => dispatch(actions.openModal(modalParams)),
    closeModal: () => dispatch(actions.closeModal())
  };
};
export default connect(null, mapDispatchToProps)(ModalVideoProduit);
