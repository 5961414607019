import React from 'react'



const Equipe= (props)=> {
  const clickPerson=()=>{
    props.selectPerson(props.data.Name)
  }
  return (
    <div onClick={clickPerson}>
         <div  className="imgEquipe">
                <img src={props.data?.image} alt={props.data.Name}  />    
                  <h4>{props.data.Name}</h4>
                  <p>{props.data.profession}</p>
              
                
            </div>
    </div>
  )
}

export default Equipe;