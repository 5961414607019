import React from "react";
import { BiMouse } from "react-icons/bi";
import './ScrollEffect.scss';
import { Link } from 'react-scroll'

export default function ScrollEffect() {
    return (
        <Link activeClass="active" className="scrolling" to="scrolling" spy={true} smooth={true} offset={-100} duration={500} >
        <div className="scroll-effect">
            <BiMouse className="scroll-effect_icon" />
            <div className="scroll-effect_line"></div>
            <div className="scroll-effect_text">Scroll</div>
        </div>
        </Link>
    );
}
