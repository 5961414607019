import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import "./Blog.scss";
import Button from "../../Components/Button";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Skeleton } from "@chakra-ui/core";

const BlogContainer = ({ children }) => {
    return <div className="blog-pagination">{children}</div>;
};


const BlogCards = (props) => {
    const [loading, setLoading] = useState(true);

  
    const [itemCount, setItemCount] = useState(null);
    const [arr, setArr] = useState([]);
    const [hide, setHide] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const itemsCountPerPage = 6;
    const scrollToTop = () => window.scrollTo(0, 250);
    const [pagination, setPagination] = useState({
        start: 0,
        end: 4,
    });

    useEffect(
        
        function promiseCategory() {
            const madUpService = new MadUpService();
            madUpService.getAllCategory().then((res) => {
                const result = res.data.filter(
                    (arr) => arr.slug === props.slug
                );
                const resultItem = result[0];
                const id = { ...resultItem }.id;
                const value = itemsCountPerPage * activePage;
                const startValue = value - itemsCountPerPage;
                const endValue = value;
                setPagination({ start: startValue, end: endValue });
                setArr(id);
            });
        },
        [props.slug, activePage]
    );

    const [data, setData] = useState([]);
   
    useEffect(
        function promiseData() {
            const madUpService = new MadUpService();
            madUpService.getCategoryId(props.lang,arr).then((res) => {
                setData(res.data);
                const count = res.data.length;
                const result = count > 0 ? false : true;
                console.log("result", result);
                setHide(result);
                setItemCount(count);
                setLoading(false);
            });
        },
        [arr, props.lang]
    );

    const handlePageChange = (e) => {
        setActivePage(e);
        scrollToTop();
    };
    const onItemSelected = (id, slugId) => {
        props.history.push(`/${props.lang}/actualite/${slugId}/${id}`);
    };

    function Loading() {
        const arr = ["", "", "", ""];
        return arr.map((elem,index) => {
            return (
                <div className="blog-card">
                    <Skeleton className="blog-card_image">
                        <div>contents wrapped</div>
                        <div>won't be visible</div>
                    </Skeleton>
                    <div>
                        <Skeleton
                            height="20px"
                            my="10px"
                            className="blog-card_top-row"
                        />
                        <div className="blog-card_bottom-row">
                            <Skeleton
                                height="20px"
                                my="10px"
                                className="blog-card_title"
                            />
                            <Skeleton
                                height="20px"
                                my="10px"
                                className="blog-card_text"
                            />
                            <Skeleton
                                height="20px"
                                my="10px"
                                className="button button_blue_light"
                            />
                        </div>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
         
                <MDBAnimation type="fadeIn">
                    <BlogContainer>
                        {loading && <Loading />}
                        {data
                            .slice(pagination.start, pagination.end)
                            .map((blogData,index) => {
                                function convertDate(inputFormat) {
                                    function pad(s) {
                                        return s < 10 ? "0" + s : s;
                                    }
                                    var d = new Date(inputFormat);
                                    return [
                                        pad(d.getDate()),
                                        pad(d.getMonth() + 1),
                                        d.getFullYear(),
                                    ].join("/");
                                }

                                return (
                                    <div
                                        className="blog-card"
                                        onClick={() => {
                                            scrollToTop();
                                            onItemSelected(
                                                blogData.slug,
                                                blogData.category.slug
                                            );
                                        }}
                                        key={`blogItem_${index}`}
                                    >
                                        <div
                                            className="blog-card_image"
                                            style={{
                                                background: `url(${blogData.cover.data.full_url})`,
                                            }}
                                        ></div>
                                        <div className="blog-card_top-row">
                                            <div className="blog-card_category">
                                                {`${blogData.category.slug}`}
                                            </div>
                                            <div className="blog-card_date">
                                                {convertDate(blogData.created_on)}
                                            </div>
                                        </div>
                                        <div className="blog-card_bottom-row">
                                            <h4 className="blog-card_title">
                                                {blogData.translations[0].title}
                                            </h4>
                                            <div className="blog-card_text">
                                                {
                                                    blogData.translations[0]
                                                        .content_short
                                                }
                                            </div>
                                            <Button
                                                color="blue_light"
                                                text={props.lang === "fr" ? "Lire la suite" : "Read more"}
                                                src="https://mad-up.com/"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        <Pagination
                            hideDisabled={hide}
                            activePage={activePage}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={itemCount}
                            onChange={(e) => handlePageChange(e)}
                        />
                    </BlogContainer>
                </MDBAnimation>
        
        </>
    );
};

export default withRouter(BlogCards);
