import React, { useState, useEffect } from "react";
import "./TestimonialEquipe.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
import Equipe from "./Equipe";

export default (props, {deviceType}) => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
     
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
     
    }
  };


  
  return (
    <>
    
  <Carousel
    additionalTransfrom={0}
    arrows
    removeArrowOnDeviceType={["tablet", "mobile"]}
    autoPlaySpeed={3000}
    centerMode={false}
    className="container carouselDesktop"
    containerClass="container"
    dotListClass="custom-dot-list-style"
    draggable={false}
    focusOnSelect={false}
    infinite
    itemClass="carousel-item-padding-40-px"
    keyBoardControl
    minimumTouchDrag={80}
    pauseOnHover
    renderArrowsWhenDisabled={true}
    renderButtonGroupOutside={true}
    renderDotsOutside
    responsive={responsive}
    rewind={false}
    rewindWithAnimation={false}
    rtl={false}
    shouldResetAutoplay
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
    afterChange={( previousSlide,{ currentSlide } )=>{
        console.log("current",currentSlide);
        console.log("previous",previousSlide);
        props.updateSelectedPerson(currentSlide);
      
        
    } 
  }

  >
        
        {props.data.items.map((item, id) => {
          return (
           <Equipe  data={item}  key={id} index={id} selectPerson={props.selectPerson} />
          );
        })}
      </Carousel>
     
    </>
  )

}