import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";

import BigTitle from "../../Components/BigTitle";
import Button from "../../Components/Button";
import Breadcrumb from "../../Components/Breadcrumb";
import * as dataJSON from "../../Data/MadUpPro.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Components/Meta";

const MadApp = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
         <Meta meta={meta}/>
            <MDBAnimation>
                  <Breadcrumb title="A venir....." />
            
            </MDBAnimation>
        </>
    );
};

export default withRouter(MadApp);
