import React, { useState, useEffect } from "react";
import "./ButtonReadMore.scss";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/ButtonDemo.json";
import { HashLink } from 'react-router-hash-link';
import { FiRefreshCw } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
const ButtonReadMore = (props) => {
    const lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);

    const scrollToTop = () => window.scrollTo(0, 0);

    return (
        <div className="read_More">
            
            <HashLink 
                smooth to={`/${lang}${data.read.src}`}
                className="read_More"
                onClick={scrollToTop()}>

            <i className="fa-sharp fa-solid fa-arrows-rotate"/>
                
                <span  className="text">{data.read.text}</span>
            
            </HashLink>
            

        </div>
    );
};

export default withRouter(ButtonReadMore);
