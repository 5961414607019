import React from "react";
import styled from "styled-components";
import Logo from "../Logo";
import Language from "../Languages";
import Burger from "./Burger";
import IconBoutique from "../IconBoutique/IconBoutique";
//import FaUserCircle from "../IconConnexion/IconConnexion";
const Nav = styled.nav`
    width:100%;
    height: 85px;
    border-bottom: 2px solid #f1f1f1;
    //padding:0 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: nowrap;
    .logoWrapper {
        padding: 15px 0;
    }
   
    @media (max-width: 1200px) {
        width:77%;
        height:80px;
       
   }
   @media (max-width: 768px) {
    width:82%;
    height:65px;
   
    }
    @media (max-width: 768px) and (orientation: landscape) {
        overflow: scroll;
        }
       
   
`;

const Navbar = () => {
    return (
        <Nav>
            <div className="right">
                <Burger />
                <IconBoutique />
                {/* <FaUserCircle /> */}
                <Language />
            </div>
            <div className="flex">
                <Logo />       
            </div>
        </Nav>
    );
};

export default Navbar;
