import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Breadcrumb from "../../Components/Breadcrumb";
import Button from "../../Components/Button";
import "./MentionsLegales.scss";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/MentionsLegales.json";

import Meta from "../../Components/Meta";

const MentionsLegales = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
    return (
        <>
            <>
            <Meta meta={meta}/>
                <MDBAnimation type="fadeIn">
                    <Breadcrumb title={data.breadcrumb} link="" />
                {lang === "fr" &&
                    <section className="mentions-legales">
                    <div className="container">
                        <p>
                            En vertu de l'article 6 de la loi n° 2004-575 du
                            21 juin 2004 pour la confiance dans l'économie
                            numérique, il est précisé aux utilisateurs du
                            site www.mad-up.com l'identité des différents
                            intervenants dans le cadre de sa réalisation et
                            de son suivi :
                        </p>
                        <div className="contact-group">
                            {/* <div className="item">
                                <div className="contact-group_title">
                                    PROPRIÉTAIRE DU SITE MAD-UP SAS
                                </div>
                                <nav className="contact-group_list">
                                    <div>
                                        <b>Siège social :</b> 7 rue Alexandre Fleming, 49000 Angers, France
                                    </div>
                                    <div>
                                        <b>SIRET : </b> 83524834500026
                                    </div>
                                </nav>
                            </div> */}
                            <div className="item">
                                <div className="contact-group_title">
                                     ÉDITEUR DU SITE INTERNET: 
                                </div>
                                <nav className="contact-group_list">
                                     <div>
                                        <b>Dénomination sociale de l'éditeur :</b> MAD-UP SAS
                                    </div>
                                    <div>
                                        <b>Siège social :</b>  7 rue Alexandre Fleming, 49000 Angers, France
                                    </div>
                                    {/* <div>
                                        <b>Site web :</b> webvision360.com
                                    </div> */}
                                    <div>
                                        <b>Email :</b>{" "}
                                        contact@mad-up.com
                                    </div>
                                    {/* <div>
                                        <b>Téléphone :</b> +33(0)1 87 16 89 87
                                    </div> */}
                                    <div>
                                        <b>SIRET :</b> 83524834500026
                                    </div>
                                    <div>
                                        <b>TVA :</b> FR16835248345
                                    </div>
                                </nav>
                            </div>
                            <div className="item">
                                <div className="contact-group_title">
                                    SITE HÉBERGÉ PAR OVH
                                </div>
                                <nav className="contact-group_list">
                                    <div>
                                        <b>Siège social :</b> 2 rue
                                        Kellermann – 59100 Roubaix – France
                                    </div>
                                    <div>
                                        <b>TVA :</b> FR 22 424 761 419
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="mentions-legales_title">
                            Conditions générales d’utilisation du site et
                            des services proposés
                        </div>
                        <p>
                            L’utilisation du site www.mad-up.com implique
                            l’acceptation pleine et entière des conditions
                            générales d’utilisation ci-après décrites.
                        </p>
                        <p>
                            Ces conditions d’utilisation sont susceptibles
                            d’être modifiées ou complétées à tout moment,
                            les utilisateurs du site www.mad-up.com sont
                            donc invités à les consulter de manière
                            régulière.
                        </p>
                        <p>
                            Ce site est normalement accessible à tout moment
                            aux utilisateurs. Une interruption pour raison
                            de maintenance technique peut être toutefois
                            décidée par MAD-UP.
                        </p>
                        <p>
                            Le site www.mad-up.com est mis à jour
                            régulièrement. De la même façon, les mentions
                            légales peuvent être modifiées à tout moment :
                            elles s’imposent néanmoins à l’utilisateur qui
                            est invité à s’y référer le plus souvent
                            possible afin d’en prendre connaissance.
                        </p>
                        <div className="mentions-legales_title">
                            Propriété intellectuelle
                        </div>
                        <p>
                            L’ensemble de ce site relève des législations
                            Françaises et Internationales sur les droits
                            d’auteur et la propriété intellectuelle. Tous
                            les droits de reproduction sont réservés pour
                            les textes et les photographies de ce site. La
                            reproduction de tout ou partie de ce site sur un
                            support électronique ou autre quel qu’il soit,
                            est formellement interdite sauf{" "}
                        </p>
                        <p>
                            Afin d’avoir plus d’informations sur la
                            protection et le traitement de vos données à
                            caractère personnel ainsi que sur les cookies,
                            nous vous invitons à consulter notre page «
                            Protection de la vie privée et cookies »
                            accessible au bas de chaque page du Site.
                        </p>
                        <div className="mentions-legales_title">
                            Description des services fournis
                        </div>
                        <p>
                            Le site www.mad-up.com a pour objet de fournir
                            une information concernant l’ensemble des
                            activités de la société.
                        </p>
                        <p>
                            MAD-UP s’efforce de fournir sur son site des
                            informations aussi précises que possible.
                            Toutefois, il ne pourra être tenue responsable
                            des omissions, des inexactitudes et des carences
                            dans la mise à jour, qu’elles soient de son fait
                            ou du fait des tiers partenaires qui lui
                            fournissent ces informations.
                        </p>
                        <p>
                            Tous les informations indiquées sur le site
                            www.mad-up.com sont données à titre indicatif,
                            et sont susceptibles d’évoluer. Par ailleurs,
                            les renseignements figurant sur le site
                            www.mad-up.com ne sont pas exhaustifs. Ils sont
                            donnés sous réserve de modifications ayant été
                            apportées depuis leur mise en ligne.
                        </p>
                        <div className="mentions-legales_title">
                            Propriété intellectuelle et contrefaçons
                        </div>
                        <p>
                            MAD-UP est propriétaire des droits de propriété
                            intellectuelle ou détient les droits d’usage sur
                            tous les éléments accessibles sur le site,
                            notamment les textes, images, graphismes, logo,
                            icônes, sons, logiciels.
                        </p>
                        <p>
                            Toute reproduction, représentation,
                            modification, publication, adaptation de tout ou
                            partie des éléments du site, quel que soit le
                            moyen ou le procédé utilisé, est interdite, sauf
                            autorisation écrite préalable de : MAD-UP.
                        </p>
                        <p>
                            Toute exploitation non autorisée du site ou de
                            l’un quelconque des éléments qu’il contient sera
                            considérée comme constitutive d’une contrefaçon
                            et poursuivie conformément aux dispositions des
                            articles L.335-2 et suivants du Code de
                            Propriété Intellectuelle.
                        </p>
                        <div className="mentions-legales_title">
                            Limitations de responsabilité
                        </div>
                        <p>
                            MAD-UP ne pourra être tenue responsable des
                            dommages directs et indirects causés au matériel
                            de l’utilisateur, lors de l’accès au site
                            www.mad-up.com, et résultant soit de
                            l’utilisation d’un matériel ne répondant pas aux
                            spécifications indiquées au point 4, soit de
                            l’apparition d’un bug ou d’une incompatibilité.
                        </p>
                        <p>
                            MAD-UP ne pourra également être tenue
                            responsable des dommages indirects (tels par
                            exemple qu’une perte de marché ou perte d’une
                            chance) consécutifs à l’utilisation du site
                            www.mad-up.com.
                        </p>
                        <p>
                            Des espaces interactifs (possibilité de poser
                            des questions dans l’espace contact) sont à la
                            disposition des utilisateurs. MAD-UP se réserve
                            le droit de supprimer, sans mise en demeure
                            préalable, tout contenu déposé dans cet espace
                            qui contreviendrait à la législation applicable
                            en France, en particulier aux dispositions
                            relatives à la protection des données. Le cas
                            échéant, MAD-UP se réserve également la
                            possibilité de mettre en cause la responsabilité
                            civile et/ou pénale de l’utilisateur, notamment
                            en cas de message à caractère raciste,
                            injurieux, diffamant, ou pornographique, quel
                            que soit le support utilisé (texte,
                            photographie…).
                        </p>
                        <div className="mentions-legales_title">
                            Gestion des données personnelles
                        </div>
                        <p>
                            En France, les données personnelles sont
                            notamment protégées par la loi n° 78-87 du 6
                            janvier 1978, la loi n° 2004-801 du 6 août 2004,
                            l'article L. 226-13 du Code pénal et la
                            Directive Européenne du 24 octobre 1995.
                        </p>
                        <p>
                            A l'occasion de l'utilisation du site
                            www.mad-up.com, peuvent êtres recueillies :
                            l'URL des liens par l'intermédiaire desquels
                            l'utilisateur a accédé au site www.mad-up.com,
                            le fournisseur d'accès de l'utilisateur,
                            l'adresse de protocole Internet (IP) de
                            l'utilisateur.
                        </p>
                        <p>
                            En tout état de cause MAD-UP ne collecte des
                            informations personnelles relatives à
                            l'utilisateur que pour le besoin de certains
                            services proposés par le site www.mad-up.com.
                            L'utilisateur fournit ces informations en toute
                            connaissance de cause, notamment lorsqu'il
                            procède par lui-même à leur saisie. Il est alors
                            précisé à l'utilisateur du site www.mad-up.com
                            l’obligation ou non de fournir ces informations.
                        </p>
                        <p>
                            Conformément aux dispositions des articles 38 et
                            suivants de la loi 78-17 du 6 janvier 1978
                            relative à l’informatique, aux fichiers et aux
                            libertés, tout utilisateur dispose d’un droit
                            d’accès, de rectification et d’opposition aux
                            données personnelles le concernant, en
                            effectuant sa demande écrite et signée,
                            accompagnée d’une copie du titre d’identité avec
                            signature du titulaire de la pièce, en précisant
                            l’adresse à laquelle la réponse doit être
                            envoyée.
                        </p>
                        <p>
                            Conformément aux dispositions des articles 38 et
                            suivants de la loi 78-17 du 6 janvier 1978
                            relative à l’informatique, aux fichiers et aux
                            libertés, tout utilisateur dispose d’un droit
                            d’accès, de rectification et d’opposition aux
                            données personnelles le concernant, en
                            effectuant sa demande écrite et signée,
                            accompagnée d’une copie du titre d’identité avec
                            signature du titulaire de la pièce, en précisant
                            l’adresse à laquelle la réponse doit être
                            envoyée.
                        </p>
                        <p>
                            Le site n'est pas déclaré à la CNIL car il ne
                            recueille pas d'informations personnelles. .
                        </p>
                        <p>
                            Les bases de données sont protégées par les
                            dispositions de la loi du 1er juillet 1998
                            transposant la directive 96/9 du 11 mars 1996
                            relative à la protection juridique des bases de
                            données.
                        </p>
                        <div className="mentions-legales_title">
                            Liens hypertextes et cookies
                        </div>
                        <p>
                            Le site www.mad-up.com contient un certain
                            nombre de liens hypertextes vers d’autres sites,
                            mis en place avec l’autorisation de MAD-UP.
                            Cependant, MAD-UP n’a pas la possibilité de
                            vérifier le contenu des sites ainsi visités, et
                            n’assumera en conséquence aucune responsabilité
                            de ce fait.
                        </p>
                        <p>
                            Le site www.mad-up.com est susceptible de
                            provoquer l’installation de cookie(s) sur
                            l’ordinateur de l’utilisateur. Un cookie est un
                            fichier de petite taille, qui ne permet pas
                            l’identification de l’utilisateur, mais qui
                            enregistre des informations relatives à la
                            navigation d’un ordinateur sur un site. Les
                            données ainsi obtenues visent à faciliter la
                            navigation ultérieure sur le site, et ont
                            également vocation à permettre diverses mesures
                            de fréquentation.
                        </p>
                        <p>
                            Le refus d’installation d’un cookie peut
                            entraîner l’impossibilité d’accéder à certains
                            services. L’utilisateur peut toutefois
                            configurer son ordinateur de la manière
                            suivante, pour refuser l’installation des
                            cookies :
                        </p>
                        <p>
                            <b>Sous Internet Explorer :</b> onglet outil
                            (pictogramme en forme de rouage en haut a
                            droite) / options internet. Cliquez sur
                            Confidentialité et choisissez Bloquer tous les
                            cookies. Validez sur Ok.
                        </p>
                        <p>
                            <b>Sous Firefox :</b> en haut de la fenêtre du
                            navigateur, cliquez sur le bouton Firefox, puis
                            aller dans l'onglet Options. Cliquer sur
                            l'onglet Vie privée. Paramétrez les Règles de
                            conservation sur : utiliser les paramètres
                            personnalisés pour l'historique. Enfin
                            décochez-la pour désactiver les cookies.
                        </p>
                        <p>
                            <b>Sous Safari :</b> Cliquez en haut à droite du
                            navigateur sur le pictogramme de menu (symbolisé
                            par un rouage). Sélectionnez Paramètres. Cliquez
                            sur Afficher les paramètres avancés. Dans la
                            section "Confidentialité", cliquez sur
                            Paramètres de contenu. Dans la section
                            "Cookies", vous pouvez bloquer les cookies.
                        </p>
                        <p>
                            <b>Sous Chrome :</b> Cliquez en haut à droite du
                            navigateur sur le pictogramme de menu (symbolisé
                            par trois lignes horizontales). Sélectionnez
                            Paramètres. Cliquez sur Afficher les paramètres
                            avancés. Dans la section "Confidentialité",
                            cliquez sur préférences. Dans l'onglet
                            "Confidentialité", vous pouvez bloquer les
                            cookies.
                        </p>
                        <div className="mentions-legales_title">
                            Droit applicable et attribution de juridiction
                        </div>
                        <p>
                            Tout litige en relation avec l’utilisation du
                            site www.mad-up.com est soumis au droit
                            français. Il est fait attribution exclusive de
                            juridiction aux tribunaux compétents d'Angers
                            FRANCE
                        </p>
                        <div className="mentions-legales_title">
                            Les principales lois concernées.
                        </div>
                        <p>
                            Loi n° 78-17 du 6 janvier 1978, notamment
                            modifiée par la loi n° 2004-801 du 6 août 2004
                            relative à l'informatique, aux fichiers et aux
                            libertés.
                        </p>
                        <p>
                            Loi n° 2004-575 du 21 juin 2004 pour la
                            confiance dans l'économie numérique.
                        </p>
                        <div className="mentions-legales_title">
                            Lexique
                        </div>
                        <p>
                            <b>Utilisateur :</b> Internaute se connectant,
                            utilisant le site susnommé.
                        </p>
                        <p>
                            <b>Informations personnelles :</b> « les
                            informations qui permettent, sous quelque forme
                            que ce soit, directement ou non,
                            l'identification des personnes physiques
                            auxquelles elles s'appliquent » (article 4 de la
                            loi n° 78-17 du 6 janvier 1978).
                        </p>
                        <div className="mentions-legales_title">
                            Crédits :
                        </div>
                        <p>
                            Le modèle de mentions légales est offert par
                            Subdelirium.com
                        </p>
                        <p>
                            <b>Photos :</b> MAD-UP, AC Technologies,
                            FreePix, AdobeStock
                        </p>
                        <p>
                            <b>Videos :</b> Adobe Stock, AC Technologies,
                            MAD-UP
                        </p>
                        <p>
                            <b>Contenu :</b> MAD-UP
                        </p>
                        <Button
                            color="blue"
                            text="Pour plus d'information"
                            src={`/${lang}/contact`}
                        />
                    </div>
                </section>
                }
                {lang === "en" &&
                    <section className="mentions-legales">
                    <div className="container">
                        <p>
                        Under Article 6 of the French Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, it is clear to users of the site www.mad-up.com the identity of the various stakeholders in the framework for its implementation and monitoring:
                        </p>
                        <div className="contact-group">
                            {/* <div className="item">
                                <div className="contact-group_title">
                                    SITE OWNER MAD-UP SAS
                                </div>
                                <nav className="contact-group_list">
                                    <div>
                                        <b>Head office :</b> 22, rue Roger Amsler, 49000 Angers, France
                                    </div>
                                    <div>
                                        <b>SIRET : </b> 83524834500026
                                    </div>
                                </nav>
                            </div> */}
                            <div className="item">
                                <div className="contact-group_title">
                                        WEBSITE EDITOR: MAD-UP
                                </div>
                                <nav className="contact-group_list">
                                      <div>
                                        <b>Publisher's name:</b> MAD-UP SAS
                                    </div>
                                    <div>
                                        <b>Head office :</b> 7 rue Alexandre Fleming, 49100 Angers, France
                                    </div>
                                   
                                    <div>
                                        <b>E-mail :</b>{" "}
                                        contact@mad-up.com
                                    </div>
                                    {/* <div>
                                        <b>Phone :</b> +33(0)1 87 16 89 87
                                    </div> */}
                                    <div>
                                        <b>SIRET :</b> 83524834500026
                                    </div>
                                    <div>
                                        <b>TVA :</b> FR16835248345
                                    </div>
                                </nav>
                            </div>
                            <div className="item">
                                <div className="contact-group_title">
                                    SITE HOSTED BY OVH
                                </div>
                                <nav className="contact-group_list">
                                    <div>
                                        <b>Head office :</b> 2 rue
                                        Kellermann – 59100 Roubaix – France
                                    </div>
                                    <div>
                                        <b>TVA :</b> FR 22 424 761 419
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="mentions-legales_title">
                            General conditions of use of the site and services offered.
                        </div>
                        <p>
                            The use of the site www.mad-up.com implies full acceptance of the terms and conditions described below.
                        </p>
                        <p>
                            These conditions of use may be modified or supplemented at any time, 
                            users of the site www.mad-up.com are invited to consult them regularly.
                        </p>
                        <p>
                            This site is normally accessible to users at any time. An interruption due 
                            to technical maintenance may however be decided by MAD-UP.
                        </p>
                        <p>
                            The website www.mad-up.com is updated regularly. In the same way, 
                            the legal mentions can be modified at any time: they impose nevertheless on the user who is 
                            invited to refer to it as often as possible in order to take note of it.
                        </p>
                        <div className="mentions-legales_title">
                            Description of the services provided.
                        </div>
                        <p>
                            The website www.mad-up.com is intended to provide information on all the activities of the company.</p>
                        <p>
                            MAD-UP strives to provide as accurate information as possible on its site. 
                            However, he can not be held responsible for omissions, inaccuracies and deficiencies in the update,
                            whether by him or the third party partners who provide this information.
                        </p>
                        <p>
                            All the information indicated on the site www.mad-up.com are given as an indication, and are likely to evolve. 
                            Furthermore, the information on the site www.mad-up.com is not exhaustive. 
                            They are given subject to modifications having been made since they went on line.
                        </p>
                        <div className="mentions-legales_title">
                            Intellectual property and counterfeits.
                        </div>
                        <p>
                           MAD-UP owns the intellectual property rights or holds the rights to use all the elements available on the site, 
                            including text, images, graphics, logos, icons, sounds, software.
                        </p>
                        <p>
                            Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, 
                            whatever the means or process used, is prohibited without the prior written permission of: MAD-UP.
                        </p>
                        <p>
                            Any unauthorized use of the site or any of the elements it contains will be considered as constituting an 
                            infringement and prosecuted in accordance with the provisions of Articles L.335-2 and following of the 
                            Code of Intellectual Property.
                        </p>
                        <div className="mentions-legales_title">
                            Limitations of liability.
                        </div>
                        <p>
                            MAD-UP can not be held responsible for direct or indirect damage to the user's equipment, 
                            when accessing the site www.mad-up.com, and resulting from the use of equipment not 
                            responding to the specifications in point 4, either the appearance of a bug or an incompatibility.
                        </p>
                        <p>
                            MAD-UP may also be held liable for consequential damages (such as for example a loss of market or loss of opportunity) 
                            resulting from the use of the site www.mad-up.com.
                        </p>
                        <p>
                            Interactive spaces (possibility to ask questions in the contact area) are available to users. 
                            MAD-UP reserves the right to delete, without prior notice, any content posted in this space that 
                            would violate the law applicable in France, especially the provisions relating to data protection. 
                            Where applicable, MAD-UP also reserves the right to question the user's civil and / or criminal liability, 
                            particularly in the event of a racist, abusive, defamatory or pornographic message, regardless of the medium used. 
                            (text, photography ...).
                        </p>      
                        <div className="mentions-legales_title">
                            Management of personal data.
                        </div>
                        <p>
                            In France, personal data are protected in particular by the law n ° 78-87 of January 6th, 1978, 
                            the law n ° 2004-801 of August 6th, 2004, the article L. 226-13 of the Penal Code and the European 
                            Directive of the October 24, 1995.
                        </p>
                        <p>
                            On the occasion of the use of the site www.mad-up.com, can be collected: the 
                            URL of the links through which the user accessed the site www.mad-up.com, the supplier of user access, 
                            the Internet Protocol (IP) address of the user.
                        </p>
                        <p>
                            In any case MAD-UP only collects personal information about the user for the need of certain services 
                            offered by the site www.mad-up.com. The user provides this information with full knowledge of the facts, 
                            in particular when it proceeds by itself to their input. It is then specified to the user of the site 
                            www.mad-up.com the obligation or not to provide this information.
                        </p>
                        <p>
                            In accordance with the provisions of Article 38 et seq. Of Law 78-17 of 6 January 1978 relating to data, 
                            files and freedoms, all users have a right of access, rectification and opposition to personal data. 
                            Concerning him, by making his written and signed request, accompanied by a copy of the identity 
                            document with signature of the holder of the document, specifying the address to which the answer must be sent.
                        </p>
                        <p>
                            No personal information of the user of the site www.mad-up.com is published without the knowledge of the user, 
                            exchanged, transferred, assigned or sold to any support to others. Only the assumption of redemption MAD-UP and 
                            its rights would allow the transmission of such information to the prospective purchaser who would in turn be 
                            given the same obligation to store and modify data with respect to the user of the website www.mad-up.com.
                        </p>
                        <p>
                            The site is not declared to the CNIL because it does not collect personal information.
                        </p>
                        <p>
                            The databases are protected by the provisions of the Act of 1 July 1998 transposing Directive 96/9 of 11 March 1996 on the legal protection of databases.
                        </p>
                        <div className="mentions-legales_title">
                            Hypertext links and cookies.
                        </div>
                        <p>
                            The site www.mad-up.com contains a number of hypertext links to other sites, set up with the authorization of MAD-UP. 
                            However, MAD-UP does not have the possibility to check the contents of the sites thus visited, and will not assume 
                            any responsibility for this fact.
                        </p>
                        <p>
                            The site www.mad-up.com may cause the installation of cookie (s) on the user's computer. 
                            A cookie is a small file, which does not allow the identification of the user, 
                            but which records information relating to the navigation of a computer on a site. 
                            The data thus obtained are intended to facilitate subsequent navigation on the site, 
                            and are also intended to allow various measures of attendance.
                        </p>
                        <p>
                            The refusal to install a cookie may make it impossible to access certain services. 
                            The user can however configure his computer as follows, to refuse the installation of cookies:
                        </p>
                        <p>
                            <b>Internet Explorer :</b> tool tab (pictogram in the form of a cog in the upper right corner) / internet options. 
                            Click Privacy and choose Block all cookies. Validate on Ok.
                        </p>
                        <p>
                            <b>Firefox :</b> at the top of the browser window, click the Firefox button, then go to the Options tab. 
                            Click on the Privacy tab. Set the retention rules to: use custom settings for history. 
                            Finally uncheck it to disable cookies.
                        </p>
                        <p>
                            <b>Safari :</b> click on the top right of the browser on the menu pictogram (symbolized by a cog). 
                            Select Settings. Click Show Advanced Settings. In the "Privacy" section, click Content Settings. 
                            In the "Cookies" section, you can block cookies.
                        </p>
                        <p>
                            <b>Chrome :</b> Click at the top right of the browser on the menu icon (symbolized by three horizontal lines). 
                            Select Settings. Click Show Advanced Settings. In the "Privacy" section, click Preferences. 
                            In the "Privacy" tab, you can block cookies.
                        </p>
                        <div className="mentions-legales_title">
                            Applicable law and attribution of jurisdiction.
                        </div>
                        <p>
                            Any dispute in connection with the use of the site www.mad-up.com is subject to French law. 
                            Exclusive jurisdiction is granted to the competent courts of Angers FRANCE.
                        </p>
                        <div className="mentions-legales_title">
                            The main French laws concerned.
                        </div>
                        <p>
                            Law n ° 78-17 of January 6, 1978, notably modified by the law n ° 2004-801 of August 6, 
                            2004 relating to data processing, files and freedoms.
                        </p>
                        <p>
                            Law No. 2004-575 of 21 June 2004 on confidence in the digital economy.
                        </p>
                        <div className="mentions-legales_title">
                            Lexicon.
                        </div>
                        <p>
                            <b>User :</b> Internet user connecting, using the aforementioned site.
                        </p>
                        <p>
                            <b>Personal information:</b> "information that allows, in any form whatsoever, directly or indirectly, 
                            the identification of natural persons to whom they apply" (Article 4 of French Law No. 78-17 of 6 January 1978).
                        </p>
                        <div className="mentions-legales_title">
                            Credits :
                        </div>
                        <p>
                            The legal notice template is offered by Subdelirium.com
                        </p>
                        <p>
                            <b>Pics :</b> MAD-UP, AC Technologies, FreePix, Web Vision 360, Adobe Stock
                        </p>
                        <p>
                            <b>Videos :</b> AC Technologies, MAD-UP, Web Vision 360
                        </p>
                        <p>
                            <b>Content :</b> MAD-UP, Web Vision 360
                        </p>
                        <Button
                            color="blue"
                            text="For more information"
                            src={`/${lang}/contact`}
                        />
                    </div>
                </section>
                }
                </MDBAnimation>
            </>
        </>
    );
};
export default withRouter(MentionsLegales);
