import React from "react";
import { DiAptana } from "react-icons/di";
import { FcMinus } from "react-icons/fc";
import "./NeumorphicButton.scss";
export default function NeumorphicButton({ onItemClick, open }) {
    return (
        <div
            className={
                open ? "neumorphic-button active" : "neumorphic-button unactive"
            }
            onClick={() => {
                onItemClick(!open);
            }}
        >
            {open ? <FcMinus /> : <DiAptana />}
        </div>
    );
}
