import React, { Component } from 'react';
import './ModalExempleProduit.scss';
class ModalExampleProduit extends Component {
	render() {
		const {contenu, buttons } = this.props.modalProps;
		return (
			<div className="ModalExample">
			
				{contenu && (
					<div>
						{contenu}
					</div>
				)}
				{buttons && (
					<div className="ModalExample__buttons">
						{buttons.map((buttonProps, i) => {
							return (
								<>
								<a  {...buttonProps} key={i}>
								
							    </a>
								</>
								
							);

							
						  
						})}
					</div>
				)}
			</div>
		);
	}
}

export default ModalExampleProduit;