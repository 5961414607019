import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";

export const SendMail = (props) => {

    const initialValues = {
       
        email: "",
        
    };
    //const hostName="http://mad-up.biz/APIBACKEND/mail";
   //const hostName="http://localhost/SERVEUR/mail";
    const hostName="https://mad-up.com/APIBACKEND/mail";
    // const handleSubmit = (values) => {
    //     axios.post(hostName, values)
    //     .then((response) => {
         
    //       console.log(response)
    //       console.log(response.data);
    //       console.log(JSON.parse(response.config.data))
    //     })
    // };

    const handleSubmit = (message) => {
        fetch(hostName, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        })
          .then(response => {
          console.log(response)
        //     if (response.status==200){
        //       this.setState({messageReponse:true});
        //       this.setState({messageError: false});
        //       //console.log("state:", this.state.messageReponse)   
        //   } else{
        //     this.setState({messageError: true});
        //     this.setState({messageReponse:false});
        //   }
        })
          .catch(error => {
            
            
            console.log('Error: ', error)
            console.error(error.response)
          });
          
       };
    return (
     
            <div className="container">
            
                        <Formik
                            initialValues={initialValues}
                          
                            onSubmit={(values) =>handleSubmit(values)}
                        >
                            {({ resetForm }) => (
                                <Form>
                                   
                                    <div className="form-group mb-3">
                                        <label htmlFor="email">
                                            Email:
                                        </label>
                                        <Field
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                        />
                                       
                                    </div>
                                    
                                    <div className="form-group d-flex justify-content-end gap-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Envoyer
                                        </button>
                                        
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                


    );
};

export default withRouter(SendMail);