import React, { useState, useEffect } from "react";
import "./PartnersMap.scss";
import { MDBAnimation } from "mdbreact";
import Map from "../../Components/Map";
import Breadcrumb from "../../Components/Breadcrumb";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/PartnersMap.json";
import Meta from "../../Components/Meta";

const PartnersMap = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);
  
    return (
        <>
        <Meta meta={meta}/>
            <MDBAnimation type="fadeIn">
            {/* <Breadcrumb title={data.breadcrumb.text}/> */}
                <Map lang={lang}/>
            </MDBAnimation>
        </>
    );
};

export default withRouter(PartnersMap);
