import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Button from "../../Components/Button";
import "./Blog.scss";
import MadUpService from "../../Service/MadUpService";
import { Switch, Route, withRouter, useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import BlogCard from "./BlogCard";
import BlogCards from "./BlogCards";
import NeumorphicMenu from "../../Components/NeumorphicMenu";
import BlogList from "./BlogList";
import LatestArticle from "./LatestArticle";
import * as dataJSON from "../../Data/Blog.json";
import Pagination from "react-js-pagination";
import Meta from "../../Components/Meta";
const Breadcrumb = (props) => {
    let data = props.data;
    return (
        <section className="bradcrumb">
            <h1>{data.text}</h1>
            <h4>{data.nav}</h4>
        </section>
    );
};

const AllCards = (props) => {
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);

    const [itemCount, setItemCount] = useState(null);
    const [hide, setHide] = useState(true);
    const itemsCountPerPage = 6;
    const scrollToTop = () => window.scrollTo(0, 250);
    const [pagination, setPagination] = useState({
        start: 0,
        end: 4,
    });
    const onItemSelected = (id, slugId) => {
        props.history.push(`/${props.lang}/actualite/${slugId}/${id}`);
    };

    useEffect(() => {
        const madUpService = new MadUpService();
        madUpService.getAllArticles(props.lang).then((res) => {
            setItemCount(res.data.length);
            const value = itemsCountPerPage * activePage;
            const startValue = value - itemsCountPerPage;
            const endValue = value;
            const result = res.data.length > 0 ? false : true;
            setPagination({ start: startValue, end: endValue });
            setData(res.data);
            setHide(result);
        });
    }, [props.lang, activePage]);
    const handlePageChange = (e) => {
        setActivePage(e);
        scrollToTop();
    };
    return (
        <>
            <BlogContainer>
                {data.slice(pagination.start, pagination.end).map((data) => {
                    function convertDate(inputFormat) {
                        function pad(s) {
                            return s < 10 ? "0" + s : s;
                        }
                        var d = new Date(inputFormat);
                        return [
                            pad(d.getDate()),
                            pad(d.getMonth() + 1),
                            d.getFullYear(),
                        ].join("/");
                    }

                    return (
                        <MDBAnimation type="fadeIn">
                            <div
                                className="blog-card"
                                onClick={() => {
                                    scrollToTop();
                                    onItemSelected(
                                        data.slug,
                                        data.category.slug
                                    );
                                }}
                            >
                                <div
                                    className="blog-card_image"
                                    style={{
                                        background: `url(${data.cover.data.full_url})`,
                                    }}
                                ></div>
                                <div className="blog-card_top-row">
                                    <div className="blog-card_category">
                                        {`${data.category.slug}`}
                                    </div>
                                    <div className="blog-card_date">
                                        {convertDate(data.created_on)}
                                    </div>
                                </div>
                                <div className="blog-card_bottom-row">
                                    <h4 className="blog-card_title">
                                        {data.translations[0].title}
                                    </h4>
                                    <div className="blog-card_text">
                                        {data.translations[0].content_short}
                                    </div>
                                    <Button
                                        color="blue_light"
                                        text={
                                            props.lang === "fr"
                                                ? "Lire la suite"
                                                : "Read more"
                                        }
                                        src=""
                                    />
                                </div>
                            </div>
                        </MDBAnimation>
                    );
                })}
                <Pagination
                    hideDisabled={hide}
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={itemCount}
                    onChange={(e) => handlePageChange(e)}
                />
            </BlogContainer>
        </>
    );
};
const BlogContainer = ({ children }) => {
    return <div className="blog-pagination">{children}</div>;
};

const Blog = (props) => {
    const { lang } = props.match.params;
    const [data, setData] = useState(dataJSON.default[lang]);
    const location = useLocation();

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }
           
        },
    }); 

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [location, lang]);

    return (
        <>
         <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                {/* <Breadcrumb data={data.breadcrumb} /> */}

                <div className="container">
                    <section className="blog">
                    <SideBar />
                        <NeumorphicMenu>
                            <BlogList />
                            <LatestArticle />
                        </NeumorphicMenu>
                        
                        <Switch>
                            <Route
                                exact
                                path="/:lang/actualite"
                                render={({ match }) => {
                                    const { lang } = match.params;
                                    return (
                                        <AllCards
                                            lang={lang}
                                            history={props.history}
                                        />
                                    );
                                }}
                            />
                            <Route
                                exact
                                path="/:lang/actualite/:slug"
                                render={({ match }) => {
                                    const { lang, slug } = match.params;

                                    return (
                                        <BlogCards lang={lang} slug={slug} />
                                    );
                                }}
                            />

                            <Route
                                path="/:lang/actualite/:slug/:id"
                                exact
                                render={({ match }) => {
                                    const { lang, slug, id } = match.params;
                                    return (
                                        <BlogCard
                                            lang={lang}
                                            id={id}
                                            slug={slug}
                                        />
                                    );
                                }}
                            />
                        </Switch>
                    </section>
                </div>
            </MDBAnimation>
        </>
    );
};

export default withRouter(Blog);
