import React from "react";
import "./SocialIcon.scss";
import { ImWhatsapp } from "react-icons/im";

export default () => {
    return (
        <div className="social-icons">
            <span>
                <a
                    href="https://www.facebook.com/MADUPTraining/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="icon-facebook"></i>
                </a>
            </span>
            <span>
                <a
                    href="https://twitter.com/madup_training"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="icon-twitter"></i>
                </a>
            </span>
            <span>
                <a
                    href="https://www.instagram.com/madup_pro/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="icon-instagram-sketched"></i>
                </a>
            </span>
            <span>
                <a
                    href="https://api.whatsapp.com/send?phone=+33619890231"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <ImWhatsapp />
                </a>
            </span>
        </div>
    );
};
