import React, {useState, useEffect} from "react";
import "./Logo.scss";
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import * as dataJSON from "../../Data/NavBar.json";

const Logo = (props) => {
    let lang = props.match.params.lang;

    const [data, setData] = useState(dataJSON.default[lang]);

    useEffect(() => {
        setData(dataJSON.default[lang]);
    }, [lang]);
    return (
        <div className={`logo-wrapper`}>
            <Link to={`/${lang}`} className="logo" style={{backgroundColor:"transparent"}}>
                <img src={data.logo_full_url} alt="Logo"/>
                
            </Link>
            <div className="header-certificates">
                {
                    data.certificates.map((item,index)=>{
                        return (
                            <div className="certificate-item"  key={index}>
                                <img src={item.image} alt=""/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};
export default withRouter(Logo);
