import React, { useState, useEffect } from "react";
import { MDBAnimation } from "mdbreact";
import Application from "../../Components/Aplications";
import "./BfrExpert.scss";
import TooltipsBonhomme from "../../Components/Bonhomme/TooltipsBonhomme";
import * as dataJSON from "../../Data/BfrExpert.json";
import { withRouter } from "react-router-dom";
import Meta from "../../Components/Meta";
import BigTitle from "../../Components/BigTitle";
// import {ScrollTrigger} from "gsap/ScrollTrigger";
// import { gsap} from "gsap";
// gsap.registerPlugin(ScrollTrigger);
const BfrExpert = (props) => {
    let lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default.fr);

    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [props, lang]);
    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
            <TooltipsBonhomme data={data.tooltips}/>  
            <div className="long">
                    <BigTitle
                        text={null}
                        title={data.restriction.bigtitle.title}
                        span={data.restriction.bigtitle.span}
                        direct="left"
                    />
                </div>
                <div className="container">
                    <div className="grid-2 first-color-blue">
                        <div>
                        {data.restriction.textleft.map((p,id) => (
                            <p key={id}>{p}</p>
                        ))}
                        </div>
                        <div className="dotted_right">
                        {data.restriction.textright.map((p,id) => (
                            <p key={id}>{p}</p>
                        ))}
                        </div>
                    </div>
                </div>                   
            <div className="margeApplication" id="application">
                <Application />
            </div>

            </MDBAnimation>
        </>
    );
};

export default withRouter(BfrExpert);

