import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import ModalExempleBrochure from "../ModalExampleBrochure/ModalExempleBrochure";

import { actions } from "../../ducks/modal.js";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import "./ModalProduit.scss";
//import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha"
//const hostName="http://localhost/mail/mailBrochure";
//const hostName="https://mad-up.com/APISENDMAIL/mailBrochure";
const hostName="https://mad-up.com/api/mad-up/items/brochure_request"

class ModalProduit extends Component {
  constructor(props) {
    super(props);
      this.state = {messageReponse: false,
                    messageError: false
                    };
    }
    componentDidMount() {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
handleSubmit = (message) => {
  fetch(hostName, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  })
    .then(response => {
    
      if (response.status==200){
        this.setState({messageReponse:true});
        this.setState({messageError: false});
        //console.log("state:", this.state.messageReponse)   
    } else{
      this.setState({messageError: true});
      this.setState({messageReponse:false});
    }
  })
    .catch(error => {
       this.setState({messageError: true})
       //console.log("state:", this.state.messageError);
      console.log('Error: ', error)
    });
    
 };
  render() {
    const {legend,firstName,lastName,email,textSubmit,textBouton, reponse ,error, errorForm, errorMail}=this.props;
   
    const messageMail=this.state.messageReponse;
    const messageError=this.state.messageError;
    
   if (messageMail){
    
     document.querySelector(".message").innerHTML=reponse
     document.querySelector(".message").classList.add("reponseTrue")
   }

   if (messageError){
 
    document.querySelector(".message").innerHTML=error
    document.querySelector(".message").classList.add("reponseError")
  }
  const  InputEmail = Yup.object().shape({
    email: Yup.string().email(errorMail).required(errorForm),
    firstname: Yup.string()
        .required(errorForm),
    lastname: Yup.string()
        .required(errorForm),
    recaptcha: Yup.string().required(errorForm) 
});


    return (
      <div>
      <a
        onClick={() => {
          this.props.openModal({
            modalType: ModalExempleBrochure,
            modalProps: {
              contenu: (
                <div className="background">
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname:"",
                        email: "",
                        recaptcha: "" 
                       
                    }}
                    validationSchema={InputEmail}
                    onSubmit={(values, {resetForm}) =>{
                      this.handleSubmit(values)
                      //resetForm({values:''})
                   }
                    }
                >
                    {(
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    values,
                    setFieldValue
                  
                    
                    ) => (
                        <Form className="checkboxMail">

                            <fieldset>
                              <legend>{legend}</legend>
                            <div className="d-flex justify-content-md-between">
                            <div className="form-group mb-3">
                                <label htmlFor="name">
                                   {firstName} 
                                </label>
                                <Field
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    className="form-control"
                                   
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            
                            <div className="form-group mb-3">
                                <label htmlFor="lastname">
                                   {lastName}
                                </label>
                                <Field
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    className="form-control"
                                   
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="email">
                                    {email}
                                </label>
                               
                                <Field
                                    type="email"
    
                                    id="email"
                                    name="email"
                                    className="form-control"
                                   
                                />
                                <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            <ReCAPTCHA
                          sitekey="6LcQpgUhAAAAAB3OU0YU8VHlXQ1Xn1QBqFr02yhh"
                          render="explicit"
                          onChange={(response) => { setFieldValue("recaptcha", response); }}
                          
                        />
                            <ErrorMessage
                                name="recaptcha"
                                component="small"
                                className="text-danger"
                            />
                            <div className="buttonSubmit">
                            <button type="submit" 
                            className="colorButton"
                            disabled={isSubmitting}
                            >{textSubmit}</button>
                            </div>
                           
                          </fieldset>
                        </Form>
                    )}
                </Formik>


                <p className="message"></p>

            </div>
             
              ),
      
            }
          });
        }}
        
      >
		    <span className="buttonModal_brochure">{textBouton}</span>
	     </a>
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modalParams) => dispatch(actions.openModal(modalParams)),
    closeModal: () => dispatch(actions.closeModal())
  };
};
export default connect(null, mapDispatchToProps)(ModalProduit);
