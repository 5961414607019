import React from "react";

export default ( {col, gap, children} ) => {
   
    const style = {
        display: "grid",
        gridTemplateColumns: `repeat(${col}, 1fr)`,
        gridGap: `${gap}px`
    }
    return (
       <div className="grid" style={style}>
         {children}
       </div>
    );
};
