import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './Components/Fontello/css/fontello.css';
import './Scss/reset.scss'
import App from './Components/App';
import ModalContainer from "./containers/ModalContainer";
import { Provider } from "react-redux";
import store from "./store";
import './config';
import WebFont from 'webfontloader';
import ModalVideo from './Components/ModalVideo/ModalVideo';

WebFont.load({
    google: {
        families: ['Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900', 'sans-serif']
    }
});
ReactDOM.render(
    <Provider store={store}>
<Router>
    <script async>{(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KCLLLV6')}</script>

    {/*Google Tag Manager (noscript)*/}
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KCLLLV6"
            height="0"
            width="0"
            style={{
                display:"none",
                visibility:"hidden"}}
            title={`googletagmanager`}
        />
    </noscript>
    {/*End Google Tag Manager (noscript)*/}
    <App/>
    <ModalContainer />
    
    
 
    
</Router>
</Provider>, document.getElementById('root'));
