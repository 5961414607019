import React, { useState, useEffect, useRef } from "react";
import { MDBAnimation } from "mdbreact";
import "./Home.scss";

import Partners from "../../Components/Partners";

import BigTitle from "../../Components/BigTitle";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
import Testimonial from "../../Components/Testimonial/Testimonial";
import loadable from "@loadable/component";
import { withRouter, Link } from "react-router-dom";
import * as homeData from "../../Data/Home.json";
import { Element } from "react-scroll";
import Meta from "../../Components/Meta";
import Count from "../../Components/Count/count"
//import { HashLink as Link } from 'react-router-hash-link';

const Banner = loadable(() => import("../../Components/Banner"));

const Home = (props) => {
    const lang = props.match.params.lang;
    const [data, setData] = useState(homeData.default[lang]);

    const [meta, setMeta] = useState({
        title: homeData.default[lang].meta.title,
        description: homeData.default[lang].meta.description,
        canonical: homeData.default[lang].meta.canonical,
        image: homeData.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: homeData.default[lang].meta.keywords,
            }

        },
    });

    useEffect(() => {
        setData(homeData.default[lang]);
        setMeta({
            title: homeData.default[lang].meta.title,
            description: homeData.default[lang].meta.description,
            canonical: homeData.default[lang].meta.canonical,
            keywords: homeData.default[lang].meta.keywords,
            image: homeData.default[lang].meta.image
        });
    }, [lang]);


   

    return (
        <>
            <Meta meta={meta} />
            <MDBAnimation type="fadeIn">
                <div className="scroll-container">
                    
                    <div className="margeAbout">
                        <Banner lang={lang} />
                    </div>
                    
                    <Element name="scrolling" className="about-us_title ">
                    <BigTitle
                        text={data.about.text}
                        title={data.about.title}
                        span={data.about.span}
                        direct="left"
                    />
                </Element>
                    <section className="about-us">
                 <div className="media container">
                    <div className="image">
                        <img src="https://mad-up.com/api/uploads/mad-up/originals/Map.png" alt="Map"/>
                        
                            <div className="tooltip_circle "> </div>
                            <div className="tooltip_circle1 "></div>
                            <div className="tooltip_circle2 "></div> 
                            <div className="tooltip_circle3 "> </div>
                            <div className="tooltip_circle4 "></div> 
                            <div className="tooltip_circle5 "></div>
                            <div className="tooltip_circle6 "></div>
                            <div className="tooltip_circle7 "> </div>
                            <div className="tooltip_circle8 "></div> 
                             <div className="tooltip_circle9 "></div>
                            <div className="tooltip_circle10 "> </div>
                            <div className="tooltip_circle11 "></div> 
                             <div className="tooltip_circle12"></div>
                            <div className="tooltip_circle13 "></div>
                            <div className="tooltip_circle14 "></div> 
                             <div className="tooltip_circle15 "></div> 
                           <div className="tooltip_circle16 "></div>
                            <div className="tooltip_circle17 "></div>
                    </div>
                        <div className="content">
                        {data.about.texts.length > 1 &&
                                    data.about.texts.map((text, id) => {
                                        return <p key={id}>{text}</p>;
                                    })}
                        </div>
                </div>
      
                </section>
                <section className="module counter  container " >
                    {data.about.counts.map(({number, duration, SLUG, text, paragraphe,id}) =>
                     <Count key={id} 
                        number={number}
                        duration={duration}
                        text={text}
                        paragraphe={paragraphe}
                        SLUG={`/${lang}${SLUG}`} 
                    />)}
                </section>
                    
                    <div className="margePartenaire">
                         <Partners data={data.partners} lang={lang} />
                    </div>

                </div>
                {/* <section className="clients">
                    <ClientSlider data={data.clients} />

                </section>                   */}
                <section className="testimonials">
                    <h2 className="title">{data.testimonials.title}</h2>
                    <Testimonial
                        lang={lang}
                        btn={data.testimonials.button_text}
                    />
                </section>

               

            </MDBAnimation>
        </>
    );
};

// function ClientSlider(props) {
//     const responsive = {
//         superLargeDesktop: {
//             slidesToSlide: 5,
//             breakpoint: { max: 4000, min: 3000 },
//             items: 5,
//         },
//         desktop: {
//             breakpoint: { max: 3000, min: 1024 },
//             items: 5,
//             slidesToSlide: 5,
//         },
//         tablet: {
//             breakpoint: { max: 1024, min: 464 },
//             items: 4,
//             slidesToSlide: 4,
//         },
//         mobile: {
//             breakpoint: { max: 464, min: 0 },
//             items: 2,
//             slidesToSlide: 2,
//         },
//     };

//     return (
//         <>
//             <Carousel
//                 responsive={responsive}
//                 showDots
//                 className="container multi-item-carousel client-slider"
//             >
//                 {props.data.items.map((item, id) => {
//                     return (
//                         <div key={id}>
//                             <a href={item.url} target="_blanc">
//                                 <img src={item.image} alt={item.url} />
//                             </a>
//                         </div>
//                     );
//                 })}
//             </Carousel>
//         </>
//     );
// }
export default withRouter(Home);
