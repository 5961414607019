import React, { useState, useEffect } from "react";
import MadUpService from "../../Service/MadUpService";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/Blog.json";

const BlogList = (props) => {
   
    const [category, setCategory] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const scrollToTop = () => window.scrollTo(0, 250);
    const lang = props.match.params.lang;
    const [data, setData] = useState(dataJSON.default[lang]);
    const handleOnClick = (index) => {
     
        setActiveIndex(index); // remove the curly braces
     
    };

    useEffect(() => {
        setData(dataJSON.default[lang]);
        const madUpService = new MadUpService();
        madUpService.getAllCategory(lang).then((res) => {
            setCategory(res.data);
        });
      
    }, [lang]);

    return (
        <div className="blog-sidebar_category">
            <div className="blog-sidebar_category_title">
                {data.sidebar.category}
            </div>
            <nav className="blog-sidebar_category_list">
                {category.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            scrollToTop()
                            handleOnClick(index);
                            props.history.push(`/${lang}/actualite/${item.slug}`);
                          
                        }}
                        className={
                            activeIndex === index ? "active" : "unactive"
                        }
                    >
                        {item.translations[0].name}
                    </div>
                ))}
            </nav>
        </div>
    );
};

export default withRouter(BlogList);
