import React, { Component } from 'react';
import './ModalExemple.scss';
class ModalExample extends Component {
	render() {
		const {contenu, buttons } = this.props.modalProps;
		return (
			<div className="ModalExample">
			
				{contenu && (
					<div>
						{contenu}
					</div>
				)}
				{buttons && (
					<div className="ModalExample__buttons">
						{buttons.map((buttonProps, i) => {
							return (
								<>
								<a className="buttonModal" {...buttonProps} key={i}>
								<span className="hyphen"></span>
								<span className="buttonModal_text">{contenu} </span>
							    </a>
								</>
								
							);

							
						  
						})}
					</div>
				)}
			</div>
		);
	}
}

export default ModalExample;