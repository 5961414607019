import React from "react";
import "../Header/Header.scss";
import Navbar from "./Navbar";

export default (props) => {
    return (
        <>
            <header>
                <div className="container">
                    <Navbar {...props}/>
                </div>
            </header>
        </>
    );
};
