import React from "react";
import { Link } from "react-router-dom";
import './Button.scss'

export default ({ text, src, color }) => {
    
    return (
        <Link to={src} className={`button button_${color}`} onClick={() => window.scrollTo(0, 0)} target="_blank">
            <span className="hyphen"></span>
            <span className="button_text">{text}</span>
        </Link>
    );
};
