export default class MadUpService {
//     _apiBase = "https://www.mad-up.com/api/mad-up/items";
   _apiBase = "https://mad-up.com/api/mad-up/items";

    async getResource(url) {
        const res = await fetch(`${this._apiBase}${url}`);

        if (!res.ok) {
            throw new Error("Not fetch");
        }

        return await res.json();
    }

    getAllReviews = async (lang) => {
      
        return await this.getResource(
            `/reviews?fields=created_on,photo.data,translations.name,translations.position,translations.content,translations.language&lang=${lang}`
        );
    };

    getAllCategory = async (lang) => {
        return await this.getResource(
            `/blog_category?fields=id,sort,slug,translations.name,translations.language&lang=${lang}`
        );
    };
    getAllArticles = async (lang) => {
        return await this.getResource(
            `/blog_post?fields=id,cover.*,category.slug, slug, created_on,category.id,translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}`
        );
    };
    getArticleFilter = async (lang, id, offset, limit) => {
        return await this.getResource(`/blog_post?fields=id,cover.*,created_on,category.id,translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}&filter[category.id][eq]=${id}&offset=${offset}&limit=${limit}`
        );
    };

    getCategoryId = async (lang,id) => {
        return await this.getResource(
            `/blog_post?fields=id,category.slug,slug,cover.*,created_on,category.id,translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}&filter[category.id][eq]=${id}`
        );
    };
    
  
  
    async getArticle(lang, id) {
        const arr = await this.getResource(`/blog_post?fields=id,cover.*,category.slug, slug, created_on,category.id,translations.*&sort=-created_on&meta=result_count,filter_count,total_count&lang=fr`);
        let filter = arr.data.filter((arr) => arr.slug === id);
        filter = filter[0].id

        const res = await this.getResource(
            `/blog_post?fields=id,cover.*,created_on,category.id,translations.*, slug,category.slug,&sort=-created_on&meta=result_count,filter_count,total_count&lang=${lang}&filter[id][eq]=${filter}`
           
        );

        const result = {
            image:  this._transformUrl(res.data[0].cover.data.full_url),
            slug: res.data[0].category.slug,
            date:  this._convertDate(res.data[0].created_on),
            summary: res.data[0].translations[0].content_short,
            title: res.data[0].translations[0].title,
            html: res.data[0].translations[0].content_full,
        };
        console.log("result", result)
        return result;
       
    };

    getMapCategory = async (lang) => {
        return await this.getResource(
            `/map_category?fields=id,sort,translations.name,translations.language&lang=${lang}`
        );
    };

    getPoints = async (id, lang) => {
        return await this.getResource(
            `/map_points?fields=id,category,email,phone,location,website,image.*,logo.*,translations.*&lang=${lang}&filter[category.id][eq]=${id}`
        );
    };
   

    // _transformLang(res) {
    //     switch (lang) {
    //         case "fr":
    //             return "fr";
    //         case "en":
    //             return fr;

    //         default:
    //             return fr;
    //     }
    // }

    _transformUrl(res) {
        const http = res;
        const https = http.replace(/http:/gi, "https:");
        return https;
    }
   

    async _transformArticle(  article, transformLang) {
   
          const result = {
              image:  this._transformUrl(article.data[0].cover.data.full_url),
              slug: article.data[0].category.slug,
              date:  this._convertDate(article.data[0].created_on),
              summary: article.data[0].translations[0].content_short,
              title: article.data[0].translations[0].title,
              html: article.data[0].translations[0].content_full,
          };
          console.log("_transformArticle", result)
          return await result
     
    }
    

    _convertDate(inputFormat) {
        function pad(s) {
            return s < 10 ? "0" + s : s;
        }
        var d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
            "/"
        );
    }
}
