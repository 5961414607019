import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import ModalExample from "../ModalExample/ModalExemple";

import { actions } from "../../ducks/modal.js";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import "./ModalBFR.scss";
//import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha"
//const hostName="http://localhost/mail/sendMail";
//const hostName="https://mad-up.com/APISENDMAIL/sendMail";

const TEST_SITE_KEY = "6Lcs1wUhAAAAAK-q-L1VS8mSSLTiwlbusCZcw3KB";
class ModalBFR extends Component {

  constructor(props) {
  super(props);
    this.state = {messageReponse: false,
                  messageError: false
                  };
 this._reCaptchaRef = createRef();
  }
 

handleSubmit = (message) => {
  // const token= this._reCaptchaRef.current.execute()
  // console.log(token)
  fetch("https://mad-up.com/api/mad-up/items/study_requests", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message)
      ,
  })
  .then(response => {
    console.log(response)
    if (response.status==200){
      this.setState({messageReponse:true});
      this.setState({messageError: false});
       console.log("state:", this.state.messageReponse)   
  } else{
    this.setState({messageError: true});
    this.setState({messageReponse:false});
  }
})
  .catch(error => {
    this.setState({messageError: true})
     console.log("state:", this.state.messageError);
    console.log('Error: ', error)
  });
  
    
};

  render() {
   
    const {legend,textSpan1,textSpan2,textSpan3,membre1,membre2,membre3,membre4,membre5,membre6
      ,category1,category2,category3,firstName,lastName,email,textSubmit, textSpan, reponse, error, errorForm, errorMail}=this.props;
   
      const messageMail=this.state.messageReponse;
      const messageError=this.state.messageError;
      
     if (messageMail){
     
       document.querySelector(".message").innerHTML=reponse
       document.querySelector(".message").classList.add("reponseTrue")
     }

     if (messageError){
     
      document.querySelector(".message").innerHTML=error
      document.querySelector(".message").classList.add("reponseError")
    }
  
    const InputEmail = Yup.object().shape({
      email: Yup.string().email(errorMail).required(errorForm),
      firstname: Yup.string()
          .required(errorForm),
      lastname: Yup.string()
          .required(errorForm),
      recaptcha: Yup.string().required(errorForm) 
  });
    return (
      <div>
      <a className="buttonModal"
        onClick={() => {
          this.props.openModal({
            modalType: ModalExample,
            modalProps: {
              contenu: (
                <div className="background">
                 
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        category:[],
                        email: "",
                        membre: [],
                       recaptcha: "" ,
                      
                    }}
                    validationSchema={InputEmail}
                  
                    onSubmit={(values, {resetForm}) =>{
                      console.log(values)
                      this.handleSubmit(values)
                     //resetForm({values:''})
                  }
                   }
                >
                    {(handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    values,
                    setFieldValue
                    
                    ) => (
                        <Form className="checkboxMail">
                            
                           
                            <fieldset>
                              <legend>{legend}</legend>
                           <div className="checkbox">
                            <div role="group" aria-labelledby="checkbox-group">
                            <span>{textSpan1} </span>
                            <div>
                            <label htmlFor="membre">
                                    <Field 
                                    type="checkbox" 
                                    id="membre"
                                    name="membre" 
                                    value={membre1} />
                                   {membre1}
                                </label>
                            </div>
                                
                            <div>
                            <label htmlFor="membre">
                                    <Field 
                                    type="checkbox" 
                                    
                                    id="membre"
                                    
                                    name="membre"
                                     value={membre2} />
                                   {membre2}
                                </label>
                            </div>
                              
                               <div>
                               <label htmlFor="coude">
                                    <Field type="checkbox" 
                                    
                                    name="membre" 
                                    value={membre3} />
                                   {membre3}
                                </label>
                               </div>
                                
                                </div>   


                                <div role="group" aria-labelledby="checkbox-group">
                                <span>{textSpan2} </span>
                                <div>
                                <label htmlFor="Hanche">
                                    <Field type="checkbox"
                                     name="membre" 
                                     value={membre4} />
                                    {membre4}
                                </label>
                                </div>
                               
                               <div>
                               <label htmlFor="Cheville/Pied">
                                    <Field type="checkbox"
                                     
                                      name="membre" 
                                      value={membre5} />
                                   {membre5}
                                </label>
                               </div>
                              <div>
                              <label  htmlFor=" Poignet/Main">
                                    <Field type="checkbox"
                                     
                                    name="membre" 
                                    value={membre6} />
                                    {membre6}
                                </label>
                              </div>
                               
                                </div>
                                </div>
                            <div role="group" aria-labelledby="checkbox-group">
                             <span>{textSpan3} </span>
                             <div className="category">

                             <label  htmlFor=" rééducation">
                                    <Field type="checkbox"
                                     
                                    name="category" 
                                    value= {category1} />
                                    {category1}
                                </label>
                                <label  htmlFor="santé">
                                    <Field type="checkbox"
                                     
                                    name="category"
                                   value= {category2} />
                                     {category2}
                                </label>
                                <label  htmlFor="santé">
                                    <Field type="checkbox"
                                     
                                    name="category" 
                                    value= {category3} />
                                     {category3}
                                </label>
                             </div>
                             

                               
                            </div>
                            <div className="d-flex justify-content-md-between ">
                            <div className="form-group mb-3">
                                <label htmlFor="lastname">
                                   {lastName}
                                </label>
                                <Field
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            
                            <div className="form-group mb-3">
                                <label htmlFor="firstname">
                                   {firstName}
                                </label>
                                <Field
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="email">
                                    {email}
                                </label>
                               
                                <Field
                                    type="email"
    
                                    id="email"
                                    name="email"
                                    className="form-control"
                                />
                                <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            <ReCAPTCHA
                             ref={this._reCaptchaRef}
                             sitekey={TEST_SITE_KEY}
                             onChange={(value) => setFieldValue("recaptcha", value)}
                             size="invisible"
                         
                        />
                            {/* <ErrorMessage
                                name="recaptcha"
                                component="small"
                                className="text-danger"
                            /> */}
                            <div className="d-grid gap-2 col-4 mx-auto">
                            <button type="submit" 
                            onClick={() => this._reCaptchaRef.current.execute()}
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          
                            >{textSubmit}</button>
                            </div>
                          </fieldset>
                        </Form>
                    )}

                      
                        
                </Formik>
               
                <p className="message"></p>
                {/* <div>{p}</div> */}

            </div>
             
              ),
      
            }
          });
        }}
        text={""}
      >
        
        <span className="hyphen"></span>
		    <span className="buttonModal_text">{textSpan}</span>
	     </a>
    </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (modalParams) => dispatch(actions.openModal(modalParams)),
    closeModal: () => dispatch(actions.closeModal())
  };
};
export default connect(null, mapDispatchToProps)(ModalBFR);
