import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import { Route, withRouter } from "react-router-dom";
import Routes from "../../Routes";
import "./App.scss";
import Meta from "../Meta";
import { ThemeProvider, theme } from "@chakra-ui/core";
const App = (props) => {
    return (
        <>
             <Meta meta={{
            title: "TITLE",
            description: "description",
            canonical: "canonical",
            keywords: "keywords",
            image: "image"
        }}/>
            <ThemeProvider theme={theme}>
                <Route path="/:lang" component={Header} />
                <Routes />
                <Route path="/:lang" component={Footer} />
            </ThemeProvider>
        </>
    );
};
export default withRouter(App);
