import React from "react";
import { MDBAnimation } from "mdbreact";
import { withRouter } from "react-router-dom";
import Performance from "../../Components/BfrExpert/Performance"

const Fitness = () => {
  
    return (
        <>
           
            <MDBAnimation type="fadeIn">
          <Performance/>

            </MDBAnimation>
        </>
    );
};

export default withRouter(Fitness);