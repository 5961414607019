import React, { useState, useEffect } from "react";
import "./Testimonial.scss";
import MadUpService from "../../Service/MadUpService";
import Carousel from "react-multi-carousel";
import "../../Components/MultiItemCarousel/MultiItemCarousel.scss";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/core";

export default (props, {deviceType}) => {
    const lang = props.lang;
  
    const [reviews, setReviews] = useState([]);
 
    useEffect(() => {
        const madUpService = new MadUpService();
        madUpService.getAllReviews(lang).then((res) => {
            setReviews(res.data);
        });
    }, [lang]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            slidesToSlide: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    return (
        <>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                infiniteLoop={true}
                autoPlay={deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                deviceType={deviceType}
                stopOnHover={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                className="container multi-item-carousel testimonal-slider"
            >
                {reviews.map((review, id) => {
                    return (
                        <ReviewItem data={review} key={id} btn={props.btn} />
                    );
                })}
            </Carousel>
        </>
    );
};

const ReviewItem = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [review, setstate] = useState(props.data);
    const [isBig, setIsBig] = useState(false);
 
    useEffect(() => {
        setstate(props.data);
        if (props.data.translations[0].content.length > 180) {
            setIsBig(true);
        }
    }, [props.data]);
    return (
        <div className="testimonial">
            <div className="testimonial_image">
                <div
                    className="testimonial_round"
                    style={{
                        backgroundImage: `url(${review.photo.data.full_url})`,
                    }}
                ></div>
            </div>
            <div className="testimonial_content">
                <h4 className="testimonial_name">
                    {review.translations[0].name}
                </h4>
                <h5 className="testimonial_position">
                    {review.translations[0].position}
                </h5>
                <div
                    className={
                        isBig ? `testimonial_text biggest` : `testimonial_text`
                    }
                >
                    {review.translations[0].content}
                </div>

                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent className="testimonial_modal">
                        <ModalBody>
                            <ModalCloseButton />
                            <div className="testimonial">
                                <div className="testimonial_image">
                                    <div
                                        className="testimonial_round"
                                        style={{
                                            backgroundImage: `url(${review.photo.data.full_url})`,
                                        }}
                                    ></div>
                                </div>
                                <div className="testimonial_content">
                                    <h4 className="testimonial_name">
                                        {review.translations[0].name}
                                    </h4>
                                    <h5 className="testimonial_position">
                                        {review.translations[0].position}
                                    </h5>
                                    <div className="testimonial_text">
                                        {review.translations[0].content}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                {isBig && (
                    <div className="button button_blue" onClick={onOpen}>
                        <span className="hyphen"></span>
                        <span className="button_text"> {props.btn}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
