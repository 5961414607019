import React from "react";
import { MDBAnimation } from "mdbreact";
import { withRouter } from "react-router-dom";
import Rehub from "../../Components/BfrExpert/Rehub/Rehub";
import PostOp from "../../Components/BfrExpert/PostOp/PostOp";
import PreOp from "../../Components/BfrExpert/PreOp/PreOp";

const Reeducation = () => {
  
    return (
        <>
           
            <MDBAnimation type="fadeIn">
                <div id="preo"> <PreOp/></div>
               <div id="postop"> <PostOp/></div>
               <div id="rehub"> <Rehub/></div>
               
            

            </MDBAnimation>
        </>
    );
};

export default withRouter(Reeducation);