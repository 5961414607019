import React from 'react';
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './count.scss';

const Count = ( {  number, duration, SLUG, text, paragraphe }) => {
    return (

        <>

{/* <div class="col-md-3">
            <div className="counter">
                <div className="counter-content">
                    <i className="counter-icon fa fa-edit"></i>
                    <span className="counter-value"></span>
                    <h3 className="title"></h3>
                                    
                </div>
                </div>
                </div> */}
         <section className='sectionCount'>
          <h4 className="pcount"> {paragraphe}</h4>

          
               
     
            <div>
                {/* sans cscroll */}
            {/* <CountUp end={number} duration={duration} className="count size" suffix='%'/> */}
            {/* avec le scroll */}

            <CountUp start={0} end={number} duration={duration} className="count size" prefix="+">

                        {({ countUpRef, start }) => (
                            //quand on scroll on incremente le compteur
                            <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} className="count size" />
                            </VisibilitySensor>
                        )}

                    </CountUp> 
               
            {/* </div> */}
            {/* <div > */}
                {/* <Link to={SLUG} onClick={() => window.scrollTo(0, 0)} className="gbblue">
                    <span className="button_text">{text}</span>
                </Link> */}
             </div> 
            </section> 
        </>
    );
}

export default Count;