import React from "react";
import "./IconBoutique.scss";
import { FaShoppingCart } from "react-icons/fa";

export default () => {
    return (
<div className="iconBoutique">
    <a
        href="https://www.mad-up-store.com/" target="_blank">
        <FaShoppingCart />
    </a>

</div>      
    );
};
